import { NavLink } from "react-router-dom";
import './style.css'
import arrow from '../../imagens/ArrowRight.png'
const BlueSquareMobile = (props)=>{
    return(
        <div className="bl-mob">
            <div className="bl_img_mob"><img className="config_img"src={props.img}alt="imagem que ilustra o serviço"></img></div>
            <div className="container_blue-mob">
                <div className="mob-title-square">{props.title}</div>
                <div className="mob-text-square">{props.text}</div>
                <NavLink to={props.pagina}>
                    <div className="button-mob">
                        <div>Saiba Mais</div>
                        <img alt="seta"src={arrow}></img>                   
                    </div>
                </NavLink>
                
            </div>
        </div>
    );
}
export default BlueSquareMobile