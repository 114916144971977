import { NavLink } from "react-router-dom";
import './dropdown.css'
import img from './contmenu.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Dropdown =()=>{
  
  AOS.init({
    duration: 800
});
   return(
    <>
  <ul data-aos="fade-down" className="nav-menu">
    <div className="menu-items">
    <NavLink  to="/locacao de containers"className="menu-options">Locação de Containers</NavLink>
    <NavLink  to="/locacao de modulos habitaveis" className="menu-options">Locação de Módulos Habitáveis</NavLink>
    <NavLink  to="/servicos de transporte"className="menu-options">Serviços de Transporte</NavLink>
    <NavLink  to="/locacao de maquinas e equipamentos"className="menu-options">Locação de Máquinas e Equipamentos</NavLink>
    <NavLink  to="/locacao de moveis" className="menu-options">Locação de Móveis para Escritório</NavLink>
    <NavLink  to="/armazenagem"className="menu-options">Armazenagem</NavLink>
    <NavLink  to="/fabricacao"className="menu-options">Fabricação e Personalização de Containers e Módulos</NavLink>
    <NavLink  to="/venda de containers"className="menu-options">Venda de Containers</NavLink>
    <NavLink  to="/manutencao de container reefer"className="menu-options">Manutenção de Container Reefer </NavLink>

    </div>	
    <div className="menu-img">
      <img src={img}/>
    </div>
</ul>
</>
 );
}
export default Dropdown