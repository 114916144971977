import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/navbar/NavBar';
import { Carousel } from 'antd';
import React from 'react';
import '../projetos/projeto.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Title from '../../components/title/Title'
//imagens
import Car1 from '../projetos/imgsCarrossel/car1.png'
import Car12 from '../projetos/imgsCarrossel/car12.png'
import Car13 from '../projetos/imgsCarrossel/car13.png'
import Car14 from '../projetos/imgsCarrossel/car14.png'
import Car15 from '../projetos/imgsCarrossel/car15.png'
import Car16 from '../projetos/imgsCarrossel/car16.png'
import Car2 from '../projetos/imgsCarrossel/car2.png'
import Car22 from '../projetos/imgsCarrossel/car22.png'
import Car23 from '../projetos/imgsCarrossel/car23.png'
import Car24 from '../projetos/imgsCarrossel/car24.png'
import Car25 from '../projetos/imgsCarrossel/car25.png'
import Car26 from '../projetos/imgsCarrossel/car26.png'
import Car3 from '../projetos/imgsCarrossel/car3.png'
import Car32 from '../projetos/imgsCarrossel/car32.png'
import Car33 from '../projetos/imgsCarrossel/car33.png'
import Car34 from '../projetos/imgsCarrossel/car34.png'
import Car42 from '../projetos/imgsCarrossel/car42.png'
import Car43 from '../projetos/imgsCarrossel/car43.png'
import Car44 from '../projetos/imgsCarrossel/car44.png'
import Car5 from '../projetos/imgsCarrossel/car5.png'
import Car52 from '../projetos/imgsCarrossel/car52.png'
import Car53 from '../projetos/imgsCarrossel/car53.png'
import Car54 from '../projetos/imgsCarrossel/car54.png'
import Car55 from '../projetos/imgsCarrossel/car55.png'
import Car6 from '../projetos/imgsCarrossel/car6.png'
import Car62 from '../projetos/imgsCarrossel/car62.png'
import Car63 from '../projetos/imgsCarrossel/car63.png'
import Car64 from '../projetos/imgsCarrossel/car64.png'
import Car65 from '../projetos/imgsCarrossel/car65.png'
import Car71 from '../projetos/imgsCarrossel/car71.png'
import Car72 from '../projetos/imgsCarrossel/car72.png'
import Car73 from '../projetos/imgsCarrossel/car73.png'
import Car7 from '../projetos/imgsCarrossel/car7.png'
import Car82 from '../projetos/imgsCarrossel/car82.png'
import Car83 from '../projetos/imgsCarrossel/car83.png'
import Car84 from '../projetos/imgsCarrossel/car84.png'

import Car8 from '../projetos/imgsCarrossel/car8.png'
import Car9 from '../projetos/imgsCarrossel/car9.png'
import Car92 from '../projetos/imgsCarrossel/car92.png'
import Car93 from '../projetos/imgsCarrossel/car93.png'
import Car94 from '../projetos/imgsCarrossel/car94.png'
import Car95 from '../projetos/imgsCarrossel/car95.png'
import Car10 from '../projetos/imgsCarrossel/car10.png'
import Car102 from '../projetos/imgsCarrossel/car102.png'
import Car103 from '../projetos/imgsCarrossel/car103.png'
import Car11 from '../projetos/imgsCarrossel/car11.png'
import Car112 from '../projetos/imgsCarrossel/car112.png'
import Car113 from '../projetos/imgsCarrossel/car113.png'
import Car114 from '../projetos/imgsCarrossel/car114.png'
import Car121 from '../projetos/imgsCarrossel/car121.png'
import Car122 from '../projetos/imgsCarrossel/car122.png'


import evcar from '../projetos/imgsCarrossel/eventoscar1.png'
import evcar2 from '../projetos/imgsCarrossel/eventoscar2.png'
import evcar3 from '../projetos/imgsCarrossel/eventoscar3.png'
import evcar4 from '../projetos/imgsCarrossel/eventoscar4.png'



import Pri1 from '../projetos/pri1.png'
import Pri2 from '../projetos/pri2.png'
import Pri3 from '../projetos/pri3.png'
import Ev1 from '../projetos/evento1.png'
import Ev2 from '../projetos/evento2.png'
import Ev3 from '../projetos/evento3.png'
import Ev4 from '../projetos/evento4.png'
import Ev5 from '../projetos/evento5.png'
import Ev6 from '../projetos/evento6.png'
import Eve1 from '../projetos/Eventos1.png'
import Eve2 from '../projetos/Eventos2.png'
import Eve3 from '../projetos/Eventos3.png'
import Eve4 from '../projetos/Eventos4.png'
import Eve5 from '../projetos/Eventos5.png'
import Eve6 from '../projetos/Eventos6.png'
import NavLine from '../../components/navline/NavLine';









const contentStyle = {
    margin: 0,
    width: '300px',
    height: '300px ',
    color: '#000000',
    lineColor: '#0000000',
    lineHeight: '160px',
   
  };
  const contentStyleEv = {
    margin: 0,
    width: '376px',
    height: '357px ',
    color: '#000000',
    lineColor: '#0000000',
    lineHeight: '160px',
   
  };

const ProjetosDesktop=()=>{
    const onChange = (currentSlide) => {
        console.log(currentSlide);
      };
      AOS.init({
        duration: 800
    });

    return(
      <>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=DM+Sans" />
        
        <NavBar></NavBar>
         <NavLine/>

        <div className='projetos'>
            <Title
            text="Nossos Projetos"/>
            <div className='quadro'>
                <div className='row'>
                    <div className='imgs'> < img  className= 'config_img'src={Pri1}></img></div>
                    <div className='bluecont'>
                    <div className="w-reta line-vert" data-aos="fade-down"></div>
                    <div className='proj-cont-text'>A Alicon proporciona para você o melhor modelo de contêiner para seu negócio.
                    <br/> <br/>Conheça alguns dos nossos projetos feito especialmente para ser a solução do seu problema.
                    </div>
                    <div className='proj-line1'></div>
                    <div className='proj-line2'></div>
                    </div>
                </div>
                <div className='row'>
                    <div className='redcont'>
                    <div className="w-reta line-vert" data-aos="fade-down"></div>
                    <div className='proj-cont-text'>Nós montamos e personalizamos o contêiner para que fique do jeito que você imaginou. </div>
                    <div className='proj-line1'></div>
                    <div className='proj-line2'></div>
                    </div>
                    <div className='imgs'> < img className= 'config_img' src={Pri2}></img></div>
                </div>
                <div className='row'>
                    <div className='imgs'> < img className= 'config_img' src={Pri3}></img></div>
                    <div className='bluecont'>
                    <div className="w-reta line-vert" data-aos="fade-down"></div>
                    <div className='proj-cont-text'>Nosso objetivo é transformar a sua ideia em realidade. </div>
                    <div className='proj-line1'></div>
                    <div className='proj-line2'></div>
                    </div>
                </div>
            </div>

        <div className='ordem'>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car1}></img></div>
            <div><img src= {Car12}></img></div>
            <div><img src= {Car13}></img></div>
            
        </Carousel>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car2}></img></div>
            <div><img src= {Car22}></img></div>
            <div><img src= {Car23}></img></div>
            <div><img src= {Car24}></img></div>
            <div><img src= {Car25}></img></div>
            <div><img src= {Car26}></img> </div>
        </Carousel>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car3}></img></div>
            <div><img src= {Car32}></img></div>
            <div><img src= {Car34}></img></div>
      
        </Carousel>
        </div>
        <div className='ordem'>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car33}></img></div>
            <div><img src= {Car42}></img></div>
            <div><img src= {Car43}></img></div>
            <div><img src= {Car44}></img></div>
          
        </Carousel>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car5}></img></div>
            <div><img src= {Car52}></img></div>
            <div><img src= {Car53}></img></div>
            <div><img src= {Car54}></img></div>
            <div><img src= {Car55}></img></div>
        </Carousel>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car6}></img></div>
            <div><img src= {Car62}></img></div>
            <div><img src= {Car63}></img></div>
            <div><img src= {Car64}></img></div>
            <div><img src= {Car65}></img></div>
        </Carousel>
        </div>
        <div className='ordem'>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car7}></img></div>
            <div><img src= {Car71}></img></div>
            <div><img src= {Car72}></img></div>
            <div><img src= {Car73}></img></div>
          
        </Carousel>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car8}></img></div>
            <div><img src= {Car82}></img></div>
            <div><img src= {Car83}></img></div>
            <div><img src= {Car84}></img></div>

        
        </Carousel>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car9}></img></div>
            <div><img src= {Car92}></img></div>
            <div><img src= {Car93}></img></div>
            <div><img src= {Car94}></img></div>
            <div><img src= {Car95}></img></div>
        </Carousel>
        </div>
        <div className='ordem'>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car10}></img></div>
            <div><img src= {Car102}></img></div>
            <div><img src= {Car103}></img></div>
         
        </Carousel>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car11}></img></div>
            <div><img src= {Car112}></img></div>
            <div><img src= {Car113}></img></div>
            <div><img src= {Car114}></img></div>
   
        </Carousel>
        <Carousel style={contentStyle}afterChange={onChange}>
            <div><img src= {Car121}></img></div>
            <div><img src= {Car122}></img></div>
       
        </Carousel>
        </div>
        

        <div className="reta-preta" data-aos="fade-down"></div>
       <div className='title_red'>Eventos</div>

        <div className='text_eventos'>
            A Alicon participa de celebrações, cerimônias e festivais deixando seu
          evento mais prático e funcional. Personalizamos o contêiner para que fique
          do jeito que deseja para sua festa. Deseja que a Alicon proporcione a melhor
          experiência para seu evento? Entre em contato conosco e faça seu projeto.
        </div>

        <div className='ordem'>
            <div ><img className='config_img'src={Eve1}/></div>
            <div ><img className='config_img'src={Eve2}/></div>
        <div className='ordem_eve'>
            <div ><img className='config_img'src={Eve3}/>
            <div className='proj-rec-top'>
                
            <div>Alicon participando do Chef Ubarno com seus contêiners em Manaus.</div>
            </div>
            </div> 
        </div>
        </div>
        
        <div className='ordem'>
           <div className='ordem_eve'>
           <div ><img className='config_img'src={Eve4}/>
           <div className='proj-rec-down'>
            
           <div>Alicon participando do Bloco da Beleza com seus contêiners em Manaus.</div> 
            </div>
            </div>
            </div> 
            <div ><img className='config_img'src={Eve5}/></div>
            <div ><img className='config_img'src={Eve6}/></div>
        </div>
       
        <div className='ordem'>
            <div className='imgs_eventos'>
            <Carousel style={contentStyleEv}afterChange={onChange}>
            <div><img src= {Ev1}></img></div>
            <div><img src= {evcar}></img></div>
            <div><img src= {evcar2}></img></div>
            <div><img src= {evcar4}></img></div>

             </Carousel>
            </div>
            <div className='imgs_eventos'><img className='config_img'src={Ev2}/></div>
            <div className='imgs_eventos'><img className='config_img'src={Ev3}/></div>
        </div>
        <div className='ordem'>
            <div className='imgs_eventos'><img className='config_img'src={Ev4}/></div>
            <div className='imgs_eventos'>
            <Carousel style={contentStyleEv}afterChange={onChange}>
            <div><img src= {Ev5}></img></div>
            <div><img src= {evcar3}></img></div>
            </Carousel>
            </div>           
             <div className='imgs_eventos'><img className='config_img'src={Ev6}/></div>

        </div>
        </div>  

        <Footer></Footer>



       </>

    

    );
}
export default ProjetosDesktop