import serv1 from './imgs/sevicos4.png'
import serv2 from './imgs/sevicos5.png'
import serv3 from './imgs/servicos3.png'
import serv4 from './imgs/servicos7.png'
import serv5 from './imgs/sevicos8.png'
import serv6 from './imgs/sevicos1.png'
import serv7 from './imgs/sevicos2.png'
import serv8 from './imgs/servicos8.png'
import serv9 from './imgs/servicos9.png'
import BlueSquareMobile from "./BlueSquareMobile";
import './servicosmobile.css'
import NavBar from '../../components/navbar/NavBar';
import NavLine from '../../components/navline/NavLine'
import Title from '../../components/title/Title'
import Footer from '../../components/Footer/Footer'
    const ServicosMobile =()=>{
        return(

            <>
          
          <NavBar/>
          <NavLine/>
            <div className="serv-mob-flex">
              <Title
               text="Nossas Soluções em Contêiner"
              />
              <BlueSquareMobile
              className = "serv-mob-item"
              img= {serv1}
              title ="Locação de Containers"
              text="Precisando armazenar ou transportar suas mercadorias? Alugar um container pode ser a solução ideal para o que você está buscando."
              pagina = "/locacao de containers"
              >
                
              </BlueSquareMobile>
              <BlueSquareMobile
              className = "serv-mob-item"
              img= {serv2}
              title ="Locação de Módulos Habitáveis"
              text=" Os módulos habitáveis foram uma revolução na arquitetura , uma tecnologia  de construção segura e ecônomica que trás consigo a praticidade e parceria com o meio ambiente."
              pagina = "/locacao de modulos habitaveis"

              >
              </BlueSquareMobile>
              <BlueSquareMobile
              className = "serv-mob-item"
              img= {serv3}
              title ="Serviços de Transporte"
             text="Oferecemos serviços de tranporte para facilitar a locomoção dos seus containers, com serviços de mobilização e desmobilização."
             pagina="/servicos de transporte"
             >
              </BlueSquareMobile>
              <BlueSquareMobile
              className = "serv-mob-item"
              img= {serv4}
              title ="Locação de Máquinas e Equipamentos"
              text="Dispomos de uma diversidade de máquinas e equipamentos, como munk, empilhadeira, carreta, entre outros para atender seu serviço. "
              pagina = "/locacao de maquinas e equipamentos"
              >
              </BlueSquareMobile>
              <BlueSquareMobile
              className = "serv-mob-item"
              img= {serv5}
              title ="Locação de Móveis para Escritório"
              text=" Precisando de movéis para mobiliar seu escritório? A alicon oferece uma ótima solução para seu ambiente de trabalho. "
              pagina = "/locacao de moveis"

              >
              </BlueSquareMobile>
              <BlueSquareMobile
              className = "serv-mob-item"
              img= {serv6}
              title ="Armazenagem"
              text= " Disponibilizamos de um local para a armazenagem segura de seus containers. "
              pagina = "/armazenagem"
              >
              </BlueSquareMobile>
              <BlueSquareMobile
              className = "serv-mob-item"
              img= {serv7}
              title ="Fabricação e Personalização de Containers e Módulos"
              text="A Alicon busca transformar o seu desejo em realidade, por isso, fabricamos e personalizamos os containers de acordo com o que nossos clientes desejam. "
              pagina = "/fabricacao"
              >
              </BlueSquareMobile>
              <BlueSquareMobile
              className = "serv-mob-item"
              img= {serv8}
              title ="Venda de Containers"
              text="Deseja comprar um container para seu projeto? A Alicon disponibiliza de diversos modelos, todos já nacionalizados. "
              pagina = "/venda de containers">

              </BlueSquareMobile>
              <BlueSquareMobile
                        className="item"
                        img={serv9}
                        title="Manutenção de Container Reefer "
                        text="Precisando de um ajuste no seu container? A Alicon possui uma equipe especializada e preparada para a manutenção do seu container. "
                        pagina="/manutencao de container reefer"

                    ></BlueSquareMobile>
             </div>
             <Footer/>

            </>
        );
    } 
    export default ServicosMobile
   
   