import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/navbar/NavBar";
import mao from './mao.png'
import itj from './itj.png'
import boa from './boa.png'
import './style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import MediaQuery from 'react-responsive'
import './stylemob.css'
import Title from "../../components/title/Title"
import NavLine from "../../components/navline/NavLine";
    const Unidades =()=>{
        
          AOS.init({
            duration: 800
        });
    
        return(
            
        <>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=DM+Sans" />

        <MediaQuery minWidth={1224}>
            <NavBar/>
            <NavLine/>
       
            <div className="unidades">
                <Title
                text="Nossas Unidades"
                />
            <div className="red_container">
                <div className="img_container">
                    <img className="config_img" src={mao} alt="caminhoes"></img>
                </div>
                <div className="text">
                    <div className="titlea">Manaus</div>
                    <div className="conteudoa">Sede da Alicon e responsavél pelas principais operações. Preparada para adenter nossos cliente de forma eficiente. <br/><br/>Localizada na Av. Cosme Ferreira </div>
                </div>
            </div>
           
            <div className="white_container">
               <div className="text">
                    <div className="titleb">Boa Vista </div>
                    <div className="conteudob">Uma das filiais da Alicon, localizada no Distrito Industrial Gov. Aquilino Mota Duarde.  Inaugurada no ano de 2023 com o intuito de atender a demanda de nossos clientes.   </div>
                </div>
                <div className="img_container">
                    <img className="config_img" src={boa} alt="containers"></img>
                </div>
            </div>
           
            <div className="blue_container">
                <div className="img_container">
                    <img className="config_img" src={itj} alt="empilhadeira"></img>
                </div>
                <div className="text">
                    <div className="titlea">Ítajai  </div>
                    <div className="conteudoa">Uma das filiais da Alicon, localizada no Bairro Cordeiros, Rua Francisco Reis, 650, Sala 03 Patio. </div>
                </div>
            </div>
           
           
          
            </div>
            <Footer></Footer>
            </MediaQuery>

            <MediaQuery maxWidth={1224}>
        
            <NavBar/>
            <NavLine/>
            <div className="unidades">
                <Title
                text="Nossas Unidades"
                />
            <div className="mob-red_container">
                <div className="mob-img_container">
                    <img className="config_img" src={mao}></img>
                </div>
                <div className="mob-text">
                    <div className="mob-titlea">Manaus</div>
                    <div className="mob-conteudoa">Sede da Alicon e responsavél pelas principais operações. Preparada para adenter nossos cliente de forma eficiente. <br/><br/>Localizada na Av. Cosme Ferreira </div>
                </div>
            </div>
           
            <div className="mob-white_container">
               <div className="mob-text">
                    <div className="mob-titleb">Boa Vista </div>
                    <div className="mob-conteudob">Uma das filiais da Alicon, localizada no Distrito Industrial Gov. Aquilino Mota Duarde.  Inaugurada no ano de 2023 com o intuito de atender a demanda de nossos clientes.   </div>
                </div>
                <div className="mob-img_container">
                    <img className="config_img" src={boa}></img>
                </div>
            </div>
           
            <div className="mob-blue_container">
                <div className="mob-img_container">
                    <img className="config_img" src={itj}></img>
                </div>
                <div className="mob-text">
                    <div className="mob-titlea">Ítajai  </div>
                    <div className="mob-conteudoa">Uma das filiais da Alicon, localizada no Bairro Cordeiros, Rua Francisco Reis, 650, Sala 03 Patio. </div>
                </div>
            </div>
           
           
          
            </div>
            <Footer></Footer>
            </MediaQuery>
        </>
        );
    }
    export default Unidades