import AOS from 'aos';
import 'aos/dist/aos.css';
import './style.css'
import MediaQuery from 'react-responsive';
const Title =(props)=>{
    AOS.init({
        duration: 800
      });
    return(
        <>
        <MediaQuery minWidth={1224}>
        <div className="div-title">
        <hr className='tit-linha-horizontal' data-aos="fade-right" />
        <div className='titulo'>{props.text}</div>
        <hr className='tit-linha-horizontal-down' data-aos="fade-left" />
        </div>
        </MediaQuery>

        <MediaQuery maxWidth={1224}>
        <div className="mob-div-title">
        <hr className='mob-tit-linha-horizontal' data-aos="fade-right" />
        <div className='mob-titulo'>{props.text}</div>
        <hr className='mob-tit-linha-horizontal-down' data-aos="fade-left" />
        </div>
        </MediaQuery>
        </>
    );
}
export default Title