    import './style.css'
    
    const Rectangle = (props) =>{
       return(
        <>
        <div className="rec-row">
            <div className="rec_container_blue">
            <div className="line-10"></div>

                <div className="title-rectangle-bl">{props.title}</div>
                <div className="text-rectangle">{props.text}</div>
                <div className="line-11"></div>

            </div>
            <div className="rec_bl_img">
                <img className="config_img" src={props.img}/>
            </div>
        </div>
        </>
       );
    }
    export default Rectangle