import NavBar from '../../../components/navbar/NavBar'
import './style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from './locmoveis1.png'
import img2 from './locmoveis2.png'
import img3 from './locmoveis3.png'
import img4 from './locmoveis4.png'
import img5 from './locmoveis5.png'
import img6 from './locmoveis6.png'
import Footer from '../../../components/Footer/Footer';
import '../loc_maquinas/stylemob.css'
import MediaQuery from 'react-responsive';
import ButtonRed from '../../../components/button/ButtonRed';
import Title from '../../../components/title/Title';
import NavLine from '../../../components/navline/NavLine';

const LocMoveis = () =>{
  
      AOS.init({
        duration: 800
    });
    return(
        <>
         <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab" />
         <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />
        <MediaQuery minWidth={1224}>
        <NavBar/>
        <NavLine/>
    <div className='locmoveis'>
        <Title 
        text="Locação de Móveis para Escritório"/>
        <div className='servicos-subtitle'>A Alicon oferece a locação móveis para que você possa mobiliar seu escritótio com facilidade:  </div>
        <div className='fila'> 
        <div className='retangulo'><img src={img1}className='config_img'/> <div className='gray_box'><div  className='subtitle'>MESA PARA ESCRITÓRIO</div></div></div>
        <div className='retangulo'><img src={img2}className='config_img'/> <div className='gray_box'><div  className='subtitle2'>MESA PARA REFEITÓRIO (10 LUGARES)</div></div></div>
        <div className='retangulo'><img src={img3}className='config_img'/> <div className='gray_box'><div  className='subtitle'>MESA (04 LUGARES)</div></div></div>
        </div>
        <div className='fila'> 
        <div className='retangulo'><img src={img4}className='config_img'/> <div className='gray_box'><div  className='subtitle'>CADEIRA</div></div></div>
        <div className='retangulo'><img src={img5}className='config_img'/> <div className='gray_box'><div  className='subtitle'>CADEIRA DE PLÁSTICO</div></div></div>
        <div className='retangulo'><img src={img6}className='config_img'/> <div className='gray_box'><div  className='subtitle'>FRIGOBAR</div></div></div>
        </div>
        <ButtonRed/>

     </div>

        <Footer/>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
        <NavBar/>
        <NavLine/>
        <div className='mob-fila'>
            <Title
             text="Locação de Móveis para Escritório"/> 
            <div className='mob-servicos-subtitle'>A Alicon oferece a locação móveis para que você possa mobiliar seu escritótio com facilidade:  </div>

        <div className='mob-retangulo'><img src={img1}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>MESA PARA ESCRITÓRIO</div></div></div>
        <div className='mob-retangulo'><img src={img2}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>MESA PARA REFEITÓRIO (10 LUGARES)</div></div></div>
        <div className='mob-retangulo'><img src={img3}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>MESA (04 LUGARES)</div></div></div>
        <div className='mob-retangulo'><img src={img4}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>CADEIRA</div></div></div>
        <div className='mob-retangulo'><img src={img5}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>CADEIRA DE PLÁSTICO</div></div></div>
        <div className='mob-retangulo'><img src={img6}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>FRIGOBAR</div></div></div>
                <ButtonRed/>
        </div>
        <Footer/>
        </MediaQuery>
        
        </>
    );

}
export default LocMoveis