import { FaLinkedinIn } from "react-icons/fa";
import {AiFillInstagram} from 'react-icons/ai';
import {BsWhatsapp} from 'react-icons/bs';
import {AiOutlineGoogle} from 'react-icons/ai';
import '../Footer/footer.css'
import { NavLink} from "react-router-dom";

import MediaQuery from 'react-responsive'

function Footer() {
    return(
       <>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=DM+Sans" />
        <MediaQuery minWidth={1224}>
        <div className="footer">
     

            <div className="side">
                <NavLink to="/servicos" className='txt'>Serviços</NavLink>
                <NavLink to="/projetos" className='txt'>Projetos</NavLink>
                <NavLink to="/unidades" className='txt'>Unidades</NavLink>
                <NavLink to="/duvidas" className='txt'>Dúvidas</NavLink>
                <NavLink to="/contatos" className='txt'>Contatos</NavLink>
            </div>
          
            <div className='side-icon'>
            <a aria-label="linkedin" href="https://www.linkedin.com/company/terminal-aliconmanaus/" target="_blank" rel="noopener noreferrer">         
               <div className='square'><FaLinkedinIn  className='coloricon'/></div> </a>
                <a  aria-label="instagram"href="https://www.instagram.com/terminal_alicon/" target="_blank" rel="noopener noreferrer">                
                <div className='square'><AiFillInstagram className='coloricon'></AiFillInstagram>  </div>
                </a>              
                <NavLink to="/contatos">
                <div className='square'><BsWhatsapp className='coloricon'></BsWhatsapp>
                </div>
                </NavLink>
                
                <NavLink to="/contatos"> 
                <div className='square'><AiOutlineGoogle className='coloricon'></AiOutlineGoogle></div>
                </NavLink>
            </div>
            <div className="satus">Todos os direitos reservados | Desenvolvido por Satus Dezain</div>

            <div className='cop'>Copyright © 2023 Alicon Terminal</div>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
        <div className="mob-footer">
     

     <div className="mob-side">
         <NavLink to="/servicos" className='mob-txt'>Serviços</NavLink>
         <NavLink to="/projetos" className='mob-txt'>Projetos</NavLink>
         <NavLink to="/unidades" className='mob-txt'>Unidades</NavLink>
         <NavLink to="/duvidas" className='mob-txt'>Dúvidas</NavLink>
         <NavLink to="/contatos" className='mob-txt'>Contatos</NavLink>
     </div>
   
     <div className='mob-side-icon'>
     <a    alt="linkedin" href="https://www.linkedin.com/company/terminal-aliconmanaus/" target="_blank" rel="noopener noreferrer">         
        <div className='mob-square'><FaLinkedinIn  className='mob-coloricon'/></div> </a>
         <a alt="instagram" href="https://www.instagram.com/terminal_alicon/" target="_blank" rel="noopener noreferrer">                
         <div className='mob-square'><AiFillInstagram className='mob-coloricon'></AiFillInstagram>  </div>
         </a>              
         <NavLink to="/contatos">
         <div className='mob-square'><BsWhatsapp className='mob-coloricon' ></BsWhatsapp>
         </div>
         </NavLink>
         
         <NavLink to="/contatos"> 
         <div className='mob-square'><AiOutlineGoogle className='mob-coloricon'></AiOutlineGoogle></div>
         </NavLink>
     </div>
     <div className="mob-satus">Todos os direitos reservados | Desenvolvido por Satus Dezain</div>
     <div className='mob-cop'>Copyright © 2023 Alicon Terminal</div>
 </div>
        </MediaQuery>
        </>
    )
}

export default Footer