import './stylemob.css'
const BlueSquareLineMob =(props)=>{
    return(
        <>
         <div className="mob-rec-row">
         <div className="mob-rec_bl_img">
                <img className="config_img" src={props.img}/>
            </div>
            <div className="mob-rec_container_blue">
            <div className="mob-line-100"></div>
                <div className="mob-title-rectangle">{props.title}</div>
                <div className="mob-subtitle-rectangle">{props.subtitle}</div>
                
                <div className='mob-rec-container-conteudo'>
                <div className="mob-text-rectangle-line">
                    
                <div className='mob-text-rectangle-line-bold'>{props.textbold}</div> <div>{props.text1}</div><div>{props.text2}</div>{props.text3}<div></div>{props.text4}</div>
                <div className='mob-bsl-line-vert'></div>
                <div className="mob-text-rectangle-line2">
                    
                <div className='mob-text-rectangle-line-bold'>{props.textbold2}</div><div>{props.text5}</div><div>{props.text6}</div>{props.text7}<div></div>{props.text8}</div>
                </div>
                <div className="mob-line-111"></div>

            </div>
         
        </div>
        </>
    );
}
export default BlueSquareLineMob