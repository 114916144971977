import MediaQuery from 'react-responsive';
import './style.css'
import './stylemob.css'

import { Carousel } from 'antd'; 
const RectangleCar = (props) =>{
    
    const onChange = (currentSlide) => {
        console.log(currentSlide);
      };
    const contentStyleEv = {
        width: '226px',
        width: '641px',
        height: '304px ',
        color: '#000000',
        lineColor: '#0000000',
        lineHeight: '200px',
       
      };
      const contentStyleEvMob = {
        margin: 0,
        width: '226px',
        height: '116px ',
   
       
      };
   return(
    <>
    <MediaQuery minWidth={1224}>
    <div className="rec-row">
        <div className="rec_container_blue">
        <div className="line-10"></div>

            <div className="title-rectangle-bl">{props.title}</div>
            <div className="text-rectangle">{props.text}</div>
            <div className="line-11"></div>

        </div>
        <div className="rec_bl_img">
        <Carousel style={contentStyleEv}afterChange={onChange}>
            <div><img src= {props.img1}></img></div>
            <div><img src= {props.img2}></img></div>
            <div><img src= {props.img3}></img></div> 

            </Carousel>       
             </div>
    </div>
    </MediaQuery>

    <MediaQuery maxWidth={1224}>

    <div className="mob-rec-row">
    <div>
        
    <Carousel style={contentStyleEvMob}afterChange={onChange}>
        <div><img src= {props.img1}></img></div>
        <div><img src= {props.img2}></img></div>
        <div><img src= {props.img3}></img></div> 


        </Carousel>  
        </div>
        <div className="mob-rec_container_blue">
        <div className="mob-line-10"></div>

            <div className="mob-title-rectanglee">{props.title}</div>
            <div className="mob-text-rectangle">{props.text}</div>
            <div className="mob-line-11"></div>

        </div>
     
    </div>
    </MediaQuery>
    </>
   );
}
export default RectangleCar