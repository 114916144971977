import './stylemob.css'
    
const RectangleMob = (props) =>{
   return(
    <>
    <div className="mob-rec-row">
    <div className="mob-rec_bl_img">
            <img className="config_img" src={props.img}/>
        </div>
        <div className="mob-rec_container_blue">
        <div className="mob-line-10"></div>

            <div className="mob-title-rectanglee">{props.title}</div>
            <div className="mob-text-rectangle">{props.text}</div>
            <div className="mob-line-11"></div>

        </div>
     
    </div>
    </>
   );
}
export default RectangleMob