import './style.css'
import NavBar from '../../components/navbar/NavBar'
import React from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronRight } from "react-icons/bs";
import Footer from "../../components/Footer/Footer"
import AOS from 'aos';
import 'aos/dist/aos.css';
import MediaQuery from "react-responsive"
import './stylemob.css'
import Title from '../../components/title/Title';
import NavLine from '../../components/navline/NavLine';


const Duvidas = () => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
 
  return (
    <>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=DM+Sans" />
      <MediaQuery minWidth={1224}>
        <NavBar></NavBar>
        <NavLine/>
        <div className='duvidas'>
        <Title
        text="Perguntas Frequentes "
        />
        <div className='collapse_position'>
          <Collapsible
          contentElementId='col1'
            className='collapse'
            openedClassName='open-collapse'
            triggerClassName='header-title'
            triggerOpenedClassName='header-title-opened'
            trigger={["Quais os serviços que a Alicon oferece?", <BsChevronRight key="1" />]}>
            <div className='conteudo-collapse'>A Alicon oferece serviços de: locação de containers, locação de módulo habitáveis,
              serviços de transporte,  locação de máquinas e equipamentos, locação de móveis para
              escritório, armazenagem, fabricação e personalização de containers e módulos.</div>
          </Collapsible>

          <Collapsible
                    contentElementId='col2'

            className='collapse'
            openedClassName='open-collapse'
            triggerClassName='header-title'
            triggerOpenedClassName='header-title-opened'
            trigger={["Como funciona o processo de entrega e retirada do container?", <BsChevronRight key="2" />]}>
            <div className='conteudo-collapse'>A entrega/retirada são realizadas com o caminhão tipo munck que coloca o módulo exatamente no local que você precisa. É importante o cliente assegurar que o local tenha um bom nivelamento e não existam obstáculos no local de instalações.</div>
          </Collapsible>


          <Collapsible
                    contentElementId='col3'

            className='collapse'
            openedClassName='open-collapse'
            triggerClassName='header-title'
            triggerOpenedClassName='header-title-opened'
            trigger={["Posso personalizar o container de acordo com as minhas necessidades?", <BsChevronRight key="3" />]}>
            <div className='conteudo-collapse'>No processo de venda, sim. No de locação é necessário consultar o executivo de negócios para saber quais as adequações aplicáveis e seus devidos custos.</div>
          </Collapsible>


          <Collapsible
                    contentElementId='col4'

            className='collapse'
            openedClassName='open-collapse'
            triggerClassName='header-title'
            triggerOpenedClassName='header-title-opened'
            trigger={["Existe a opção de adicionar recursos como isolamento térmico, elétrica, etc.?",  <BsChevronRight key="4" />]}>
            <div className='conteudo-collapse'>Sim. Em todos os nossos módulos habitáveis os ambientes atendem NR18, as instalações elétrica NR10 e sanitárias NR24.</div>
          </Collapsible>


          <Collapsible
                    contentElementId='col5'

            className='collapse'
            openedClassName='open-collapse'
            triggerClassName='header-title'
            triggerOpenedClassName='header-title-opened'
            trigger={["Posso escolher onde o container será colocado?", <BsChevronRight key="5" />]}>
            <div className='conteudo-collapse'>Pode, com ressalvas de que é importante que o cliente possa assegurar que o local tenha um bom nivelamento e não existam obstáculos no local de instalações.</div>
          </Collapsible>


          <Collapsible
                    contentElementId='col6'

            className='collapse'
            openedClassName='open-collapse'
            triggerClassName='header-title'
            triggerOpenedClassName='header-title-opened'
            trigger={["Quais são os tamanhos padrões dos containers?",  <BsChevronRight key="6" />]}>
            <div className='conteudo-collapse'>Conforme DNV271 e  NBR 5978 os containers tem 20 pés (2.44 de largura 6 metros de comprimento) e o de 40 pés (2.44 de largura por 12 metros de comprimento)</div>
          </Collapsible>

          <Collapsible
                    contentElementId='col7'

            className='collapse'
            openedClassName='open-collapse'
            triggerClassName='header-title'
            triggerOpenedClassName='header-title-opened'
            trigger={["Os containers da Alicon são nacionalizados? ", <BsChevronRight key="7" />]}>
            <div className='conteudo-collapse'>Sim. Conforme a  Lei nº 9.611, de 19 de fevereiro de 1998, todos os containers da Alicon são nacionalizados.
            </div>
          </Collapsible>
        </div>
        </div>
        <Footer />
      </MediaQuery>

     
     
     
     
     
     
      <MediaQuery maxWidth={1224}>
      
      <NavBar/>
      <NavLine/>

      <div className='mob-duvidas'>
        <Title
            text="Perguntas Frequentes "/>
          <div className='collapse_position'>
          <Collapsible
            className='mob-collapse'
            openedClassName='mob-open-collapse'
            triggerClassName='mob-header-title'
            triggerOpenedClassName='mob-header-title-opened'
            trigger={["Quais os serviços que a Alicon oferece?", <BsChevronRight  key="8"/>]}>
            <div className='mob-conteudo-collapse'>A Alicon oferece serviços de: locação de containers, locação de módulo habitáveis,
              serviços de transporte,  locação de máquinas e equipamentos, locação de móveis para
              escritório, armazenagem, fabricação e personalização de containers e módulos.</div>
          </Collapsible>

          <Collapsible
            className='mob-collapse'
            openedClassName='mob-open-collapse'
            triggerClassName='mob-header-title'
            triggerOpenedClassName='mob-header-title-opened'
            trigger={["Como funciona o processo de entrega e retirada do container?", <BsChevronRight  key="9"/>]}>
            <div className='mob-conteudo-collapse'>A entrega/retirada são realizadas com o caminhão tipo munck que coloca o módulo exatamente no local que você precisa. É importante o cliente assegurar que o local tenha um bom nivelamento e não existam obstáculos no local de instalações.</div>
          </Collapsible>


          <Collapsible
            className='mob-collapse'
            openedClassName='mob-open-collapse'
            triggerClassName='mob-header-title'
            triggerOpenedClassName='mob-header-title-opened'
            trigger={["Posso personalizar o  container de acordo com as minhas necessidades?", <BsChevronRight  key="10"/>]}>
            <div className='mob-conteudo-collapse'>No processo de venda, sim. No de locação é necessário consultar o executivo de negócios para saber quais as adequações aplicáveis e seus devidos custos.</div>
          </Collapsible>


          <Collapsible
            className='mob-collapse'
            openedClassName='mob-open-collapse'
            triggerClassName='mob-header-title'
            triggerOpenedClassName='mob-header-title-opened'
            trigger={["Existe a opção de adicionar recursos como isolamento térmico, elétrica, etc.?", <BsChevronRight  key="11"/>]}>
            <div className='mob-conteudo-collapse'>Sim. Em todos os nossos módulos habitáveis os ambientes atendem NR18, as instalações elétrica NR10 e sanitárias NR24.</div>
          </Collapsible>


          <Collapsible
            className='mob-collapse'
            openedClassName='mob-open-collapse'
            triggerClassName='mob-header-title'
            triggerOpenedClassName='mob-header-title-opened'
            trigger={["Posso escolher onde o container será colocado?", <BsChevronRight  key="12"/>]}>
            <div className='mob-conteudo-collapse'>Pode, com ressalvas de que é importante que o cliente possa assegurar que o local tenha um bom nivelamento e não existam obstáculos no local de instalações.</div>
          </Collapsible>


          <Collapsible
            className='mob-collapse'
            openedClassName='mob-open-collapse'
            triggerClassName='mob-header-title'
            triggerOpenedClassName='mob-header-title-opened'
            trigger={["Quais são os tamanhos padrões dos containers?", <BsChevronRight  key="13"/>]}>
            <div className='mob-conteudo-collapse'>Conforme DNV271 e  NBR 5978 os containers tem 20 pés (2.44 de largura 6 metros de comprimento) e o de 40 pés (2.44 de largura por 12 metros de comprimento)</div>
          </Collapsible>

          <Collapsible
            className='mob-collapse'
            openedClassName='mob-open-collapse'
            triggerClassName='mob-header-title'
            triggerOpenedClassName='mob-header-title-opened'
            trigger={["Os containers da Alicon são nacionalizados? ", <BsChevronRight   key="14"/>]}>
            <div className='mob-conteudo-collapse'>Sim. Conforme a  Lei nº 9.611, de 19 de fevereiro de 1998, todos os containers da Alicon são nacionalizados.
            </div>
          </Collapsible>
        </div>
        </div>
        <Footer/>
      </MediaQuery>
    </>
  );
}
export default Duvidas
