import BlueSquareLine from '../../../components/bluesquareline/BlueSquareLine';
import './style.css'
import MediaQuery from 'react-responsive'
import NavBar from "../../../components/navbar/NavBar"
import Footer from "../../../components/Footer/Footer"
import img1 from "./loccont1.png"
import img2 from "./loccont2.png"
import img3 from "./loccont3.png"
import img4 from "./loccont4.png"
import img5 from "./loccont5.png"
import BlueSquareLineMob from '../../../components/bluesquareline/BlueSquareLineMob';
import ButtonRed from '../../../components/button/ButtonRed';
import NavLine from '../../../components/navline/NavLine';
import Title from '../../../components/title/Title';

const LocContainers =()=>{
   return(
    <>
    <MediaQuery minWidth={1224}>
      <NavBar/>
      <NavLine/>
    <div className='loc-cont'>
        <Title
        text="locação de containers"
        />
        <div className='servicos-subtitle'>A Alicon oferece a locação de containers dos seguintes módulos: </div>
    <BlueSquareLine
        title ="Container DRY 20"
        textbold="Tamanho externo"
        text1= "Comprimento:  6.058m  "
        text2="Largura : 2.438m "
        text3="Altura: 2.591m "
        text4="Capacidade máxima: 24.000 kg."

        textbold2="Tamanho interno"
        text5= "Comprimento: 5.900 m"
        text6="Largura:2.350m "
        text7="Altura:2.393m"
        text8="Capacidade cúbica: 33,2m."
        img={img1}
    ></BlueSquareLine>

<BlueSquareLine
        title ="Container DRY 40"
        textbold="Tamanho externo"
        text1= "Comprimento: 12.192m"
        text2="Largura : 2.438m "
        text3="Altura: 2.591m "
        text4="Capacidade máxima: 26.930kg."

        textbold2="Tamanho interno"
        text5= "Comprimento: 12.032m "
        text6="Largura:2.350m "
        text7="Altura:2.393m"
        text8="Capacidade cúbica: 67,7m"
        img={img2}
    ></BlueSquareLine>

    
<BlueSquareLine
        title ="Container reefer 20"
        textbold="Tamanho externo"
        text1= "Comprimento: 6.058m"
        text2="Largura: 2,438m "
        text3="Altura: 2.591m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 5.444 mm"
        text6="Largura: 2.294 mm "
        text7="Altura: 2.276 mm"
        img={img3}
    ></BlueSquareLine>

<BlueSquareLine
        title ="Container reefer 40 RH"
        textbold="Tamanho externo"
        text1= "Comprimento: 12.192m"
        text2="Largura: 2,438m "
        text3="Altura: 2.590m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 11.561 mm "
        text6="Largura: 2.268 mm "
        text7="Altura: 2.249 mm"
        img={img4}
    ></BlueSquareLine>

<BlueSquareLine
        title =" CONTaineR HC 40"
        textbold="Tamanho externo"
        text1= "Comprimento: 12,192m"
        text2="Largura: 2,438m "
        text3="Altura: 2,896m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 12,03m "
        text6="Largura: 2,352m "
        text7="Altura: 2,695m"
        img={img5}
    ></BlueSquareLine>
    <ButtonRed/>
    <Footer/>
    </div>
    </MediaQuery>
  
    <MediaQuery maxWidth={1224}>
    <NavBar/>
    <NavLine/>
    <div className='loc-cont'>
    <Title
        text="locação de containers"
        />
                <div className='mob-servicos-subtitle'>A Alicon oferece a locação de containers dos seguintes módulos: </div>

    <BlueSquareLineMob
        title ="Container DRY 20"
        textbold="Tamanho externo"
        text1= "Comprimento:  6.058m  "
        text2="Largura : 2.438m "
        text3="Altura: 2.591m "
        text4="Capacidade máxima: 24.000 kg."

        textbold2="Tamanho interno"
        text5= "Comprimento: 5.900 m"
        text6="Largura:2.350m "
        text7="Altura:2.393m"
        text8="Capacidade cúbica: 33,2m."
        img={img1}
    ></BlueSquareLineMob>

<BlueSquareLineMob
        title ="Container DRY 40"
        textbold="Tamanho externo"
        text1= "Comprimento: 12.192m"
        text2="Largura : 2.438m "
        text3="Altura: 2.591m "
        text4="Capacidade máxima: 26.930kg."

        textbold2="Tamanho interno"
        text5= "Comprimento: 12.032m "
        text6="Largura:2.350m "
        text7="Altura:2.393m"
        text8="Capacidade cúbica: 67,7m"
        img={img2}
    ></BlueSquareLineMob>

    
<BlueSquareLineMob
        title ="Container reefer 20"
        textbold="Tamanho externo"
        text1= "Comprimento: 6.058m"
        text2="Largura: 2,438m "
        text3="Altura: 2.591m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 5.444 mm"
        text6="Largura: 2.294 mm "
        text7="Altura: 2.276 mm"
        img={img3}
    ></BlueSquareLineMob>

<BlueSquareLineMob
        title ="Container reefer 40 RH"
        textbold="Tamanho externo"
        text1= "Comprimento: 12.192m"
        text2="Largura: 2,438m "
        text3="Altura: 2.590m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 11.561 mm "
        text6="Largura: 2.268 mm "
        text7="Altura: 2.249 mm"
        img={img4}
    ></BlueSquareLineMob>

<BlueSquareLineMob
         title =" CONTaineR HC 40"
         textbold="Tamanho externo"
         text1= "Comprimento: 12,192m"
         text2="Largura: 2,438m "
         text3="Altura: 2,896m "
 
         textbold2="Tamanho interno"
         text5= "Comprimento: 12,03m "
         text6="Largura: 2,352m "
         text7="Altura: 2,695m"
         img={img5}
    ></BlueSquareLineMob>
    <ButtonRed/>
    </div>
    <Footer/>
    </MediaQuery>
    </>
   );

}
export default LocContainers