
import React from "react";

import * as RiIcons from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

export const SidebarData = [
	{
		title: "Serviços",
		iconClosed: <IoIosArrowForward 
		size="18px" />,
		iconOpened: <IoIosArrowDown 
		size="18px"
		/>
		,

		subNav: [
			{
				title: "Todos os serviços",
				path: "/servicos",
			},
            {
				title: "Locação de Containers",
				path: "/locacao de containers",
			},
			{
				title: "Locação de Módulos Habitáveis",
				path: "/locacao de modulos habitaveis",
			},
           
            {
				title: "Serviços de Transporte",
				path: "/servicos de transporte",
			},
            {
				title: "Locação de Máquinas e Equipamentos",
				path: "/locacao de maquinas e equipamentos",
			},
			{
				title: "Locação de Móveis para Escritório",
				path: "/locacao de moveis",
			},
            {
				title: "Armazenagem",
				path: "/armazenagem",
			},
            {
				title: "Fabricação e Personalização",
				path: "/fabricacao",
			},
            {
				title: "Venda de Contêineres",
				path: "/venda de containers",
			},
            {
				title: "Manutenção de Container Reefer",
				path: "/manutencao de container reefer",
			},
		],
	},
	
	{
		title: "Projetos",
		path: "/projetos",
		icon:<IoIosArrowForward 
		size="18px" />,
		
	},
	
	{
		title: "Unidades",
		path: "/unidades",
		icon:<IoIosArrowForward 
		size="18px" />,

	},
    {
		title: "Contatos",
		path: "/contatos",
		icon:<IoIosArrowForward 
		size="18px" />,

	},
    {
		title: "Dúvidas",
		path: "/duvidas",
		icon:<IoIosArrowForward 
		size="18px" />,
	},
];
