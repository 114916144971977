import MediaQuery from 'react-responsive';
import './style.css'
import { Carousel } from 'antd'; 
const RectangleCar2 = (props) =>{
    
    const onChange = (currentSlide) => {
        console.log(currentSlide);
      };
    const contentStyleEv = {
        margin: 0,
        width: '641px',
        height: '304px ',
        color: '#000000',
        lineColor: '#0000000',
        lineHeight: '160px',
       
      };
      
   return(
    <>
    <MediaQuery minWidth={1224}>
    <div className="rec-row">
        <div className="rec_container_blue">
        <div className="line-10"></div>

            <div className="title-rectangle-bl">{props.title}</div>
            <div className="text-rectangle">{props.text}</div>
            <div className="line-11"></div>

        </div>
        <div className="rec_bl_img">
        <Carousel style={contentStyleEv}afterChange={onChange}>
            <div><img src= {props.img1}></img></div>
            <div><img src= {props.img2}></img></div>
            <div><img src= {props.img3}></img></div> 
            <div><img src= {props.img4}></img></div> 

            </Carousel>    


             </div>
    </div>
    </MediaQuery>

    <MediaQuery maxWidth={1224}>

<div className="mob-rec-row">
<div >


<Carousel className= "mob-rec_bl_img"afterChange={onChange}>
        <div><img src= {props.img1}></img></div>
        <div><img src= {props.img2}></img></div>
        <div><img src= {props.img3}></img></div> 
        <div><img src= {props.img4}></img></div> 


        </Carousel>            </div>
    <div className="mob-rec_container_blue">
    <div className="mob-line-10"></div>

        <div className="mob-title-rectanglee">{props.title}</div>
        <div className="mob-text-rectangle">{props.text}</div>
        <div className="mob-line-11"></div>

    </div>
 
</div>
    </MediaQuery>
    </>
   );
}
export default RectangleCar2