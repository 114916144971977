import '../loc_maquinas/stylemob.css'
import './style.css'
import img1 from './arm.png'
import img2 from './arm2.png'

import NavBar from '../../../components/navbar/NavBar'
import Footer from '../../../components/Footer/Footer'
import MediaQuery from 'react-responsive'
import ButtonRed from '../../../components/button/ButtonRed'
import Title from '../../../components/title/Title'
import NavLine from '../../../components/navline/NavLine'
const Armazenagem = () =>{
    return(
        <>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />
   <MediaQuery minWidth={1224}>
   <NavBar/>
   <NavLine/>
      <div className='mob-loc-maq'>
       <Title text="Armazenagem"/>
       <div className='servicos-subtitle'>A Alicon oferece os serviços de armazenagem ideal para seu container:</div>
       <div className='fila'> 
       <div className='arm-retangulo'><img src={img1}className='config_img'/> <div className='arm-gray_box'><div  className='subtitle'>Container 20</div></div></div>
       <div className='arm-retangulo'><img src={img2}className='config_img'/> <div className='arm-gray_box'><div  className='subtitle'>Container 40</div></div></div>
       </div>
    
       <ButtonRed/>
       </div>

       <Footer/>
   </MediaQuery>
   <MediaQuery maxWidth={1224}>
   <NavBar/>
  <NavLine/>
     <div className='mob-loc-maq'>
       <Title
       text="Armazenagaem"
       /> 
     <div className='mob-servicos-subtitle'>A Alicon oferece os serviços de armazenagem ideal para seu container:</div>

       <div className='mob-fila'> 
       <div className='mob-retangulo'><img src={img1}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>Container 20</div></div></div>
       <div className='mob-retangulo'><img src={img2}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>Container 40</div></div></div>
    
       </div>
       <ButtonRed/>
       </div>
       <Footer/>
   </MediaQuery>
       </>
    );
}
export default Armazenagem