import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ouvidoria.css'
import { ClassNames } from '@emotion/react';
import MediaQuery from 'react-responsive';
const Ouvidoria = ()=>{
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_27b73k2', 'template_cz1n5og', form.current, {
        publicKey: 'ZWng-i9UUwZaVnayd',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          alert("Sua mensagem foi enviada!");
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  
    return(
        <>
        <MediaQuery minWidth={1224}>
        <form className='ouvidoria' ref={form} onSubmit={sendEmail}>
       <textarea className="desk-ouvidoria" name="message" placeholder='Digite aqui sua mensagem'></textarea>
        <input type="submit" value="Enviar" className='botao' />
    </form>
    </MediaQuery>
    <MediaQuery maxWidth={1224}>
        <form className='ouvidoria' ref={form} onSubmit={sendEmail}>
       <textarea className="mob-ouvidoria"name="message" placeholder='Digite aqui sua mensagem'></textarea>
        <input type="submit" value="Enviar" className='mob-botao-o' />
    </form>
    </MediaQuery>

        </>
    );
}
export default Ouvidoria