import { Box, HStack, useMediaQuery } from "@chakra-ui/react";
import NavMenu from "./desktop/NavMenu";
import Logo from "./Logo";
import MediaQuery from 'react-responsive'
import NavMobile from "./mobile/NavMob";
import '../navbar/desktop/navbar.css'
const NavBar =()=>{

    return(
        <>
     <MediaQuery minWidth={1224}>
        <HStack
        w="full"
        p="irem 8rem"
        justifyContent={"space-around"}
        >
            <Logo/>
            <NavMenu/>
        </HStack>
        </MediaQuery>
        
        <MediaQuery maxWidth={1224}>
         <NavMobile/>
        </MediaQuery>
       </>
    );
}
export default NavBar