import logo from "../../imagens/logo.png"
import { Image } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
const Logo = ({size}) =>{
    return(
        <NavLink to="/">
            <Image alt="Logo da Alicon" width={size} src={logo}></Image>
        </NavLink>

    );
}
export default Logo;