import { Flex } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import Dropdown from "./DropdownMenu";
import { useState } from "react";
import './navbar.css'
import React from 'react';


const NavMenu =() =>{

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  
  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  
    return(
    <Flex gap="5rem">
       <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab" />
		    <div className = "nav__link" onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}> 
          <NavLink className = "nav__link" to="/servicos">  Serviços </NavLink>
          { isDropdownVisible && <Dropdown  className="dropdown"/> }</div>
		    <NavLink className = "nav__link" to="/projetos"> Projetos </NavLink>
        <NavLink className = "nav__link" to="/unidades"> Unidades</NavLink>
        <NavLink className = "nav__link" to="/duvidas">  Dúvidas </NavLink>
        <NavLink className = "nav__link" to="/contatos"> Contatos </NavLink>
        <NavLink className = "nav__link" to="/"> Inicio </NavLink>
 </Flex>
    );
} 
export default NavMenu