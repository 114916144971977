import { NavLink } from 'react-router-dom';
import './style.css'
import MediaQuery from 'react-responsive'
const ButtonRed =()=>{
    return(
        <>
        <MediaQuery minWidth={1224}>
        <div className='button-red'>
         <NavLink className="nav-link" to="/contatos">
                    <div className="button-solicite">
                        <div>SOLICITE SEU ORÇAMENTO</div>
                    </div>
                </NavLink>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
        <div className='mob-button-red'>
         <NavLink className="nav-link" to="/contatos">
                    <div className="mob-button-solicite">
                        <div>SOLICITE SEU ORÇAMENTO</div>
                    </div>
                </NavLink>
                </div>
                </MediaQuery>
        </>
    );

}
export default ButtonRed