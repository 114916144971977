import { Link, NavLink } from 'react-router-dom';
import NavBar from '../../components/navbar/NavBar';
import img from './Error.png'
import styled from "styled-components";
import MediaQuery from 'react-responsive';
const Erro = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;


`;
const Bold = styled.div `
font-weight: 700;
font-size: 40px;
margin-left: -30%;

`
const Topico = styled.li `
font-size: 25px;
margin-left: -70%;
margin-top: 30px;

`
const Img404 = styled.img`
width: 864px;  
height:366px;
margin-top: 100px;

`
const ButtonGoBack = styled.div`
    display: flex;
    width: 300px;
    height: 65px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #7C0408;
    background: linear-gradient(276deg, #7C0408 44.33%, #7C0408 98.56%);
    box-shadow: 0px 4px 20px 0px rgba(255, 56, 74, 0.50);   
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 120% */
    text-align: center;
    margin-top: 15px;
`
const StyledLink = styled(Link)`
text-decoration:none;
`;
const MobImg404 = styled.img`
width: 464px;  
height:166px;
margin-top: 100px;
`
const MobBold = styled.div `
font-weight: 700;
font-size: 18px;

`
const MobTopico = styled.li `
font-size: 12px;
margin-top: 30px;

`
const MobButtonGoBack = styled.div`
    display: flex;
    width: 140px;
    height: 55px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #7C0408;
    background: linear-gradient(276deg, #7C0408 44.33%, #7C0408 98.56%);
    box-shadow: 0px 4px 20px 0px rgba(255, 56, 74, 0.50);   
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 120% */
    text-align: center;
    margin-top: 35px;
`
const ErrorPage =()=>{

    return(
        <>
        <Erro>
        <MediaQuery minWidth={1224}>
        <Img404 src={img}></Img404>
        <Bold>Esta página está com problemas</Bold>
        <ul>
            <Topico>Atualize a página em poucos minutos</Topico>
            <Topico>Abrir uma nova guia</Topico>
        </ul>
        <StyledLink to="/">
        <ButtonGoBack>Voltar</ButtonGoBack>
        </StyledLink>
        </MediaQuery>

        <MediaQuery maxWidth={1224}>
        <MobImg404 src={img}></MobImg404>
        <MobBold>Esta página está com problemas</MobBold>
        <ul>
            <MobTopico>Atualize a página em poucos minutos</MobTopico>
            <MobTopico>Abrir uma nova guia</MobTopico>
        </ul>
        <StyledLink to="/">
        <MobButtonGoBack>Voltar</MobButtonGoBack>
        </StyledLink>
        </MediaQuery>
        </Erro>
        </>
    )
}
export default ErrorPage