import { useState } from 'react';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SubMenu from './SubMenu';
import { SidebarData } from './SideBarData';
import Logo from '../Logo';
import SubMenuPri from './SubmenuPri';

const Nav = styled.div`
    background: rgba(45, 46, 48, 0.35);
    height: 30px;
    display: flex;
    align-items: center;
    position:relative;

`;
 
const NavIcon = styled(Link)`
    margin-left: 90%;
    font-size: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    
`;
const SidebarLine = styled.span`
height:1px;
width:239px;
background: #FFFFFF;

`;
const SidebarNav = styled.nav
`
    background: rgba(45, 46, 48, 0.49);
    width: 239px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
    transition: 350ms;
    z-index: 100;
    
`;
 
const SidebarWrap = styled.div`
    width: 100%;
`;

const NavMobileP=()=>{
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    return(
        <>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab" />

       <Nav>

    
                    <NavIcon to="">
                        <FaIcons.FaBars
                            size="15px"
                            color='#FFFFFF'
                            onClick={showSidebar}
                        />
                    </NavIcon>
                  
                </Nav>
                <Logo size="6rem"/>
                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                        <NavIcon to="">
                            <AiIcons.AiOutlineClose
                                color='#FFFFFF'
                                size="15px"
                                onClick={showSidebar}
                            />
                        </NavIcon>
                       {SidebarData.map((item, index) => {
                            return (
                                <SubMenuPri
                                    item={item}
                                    
                                    key={index}
                                />
                            );

                        })}
                    </SidebarWrap>
                </SidebarNav>
        </>
    );

}
export default NavMobileP