import NavBar from "../../components/navbar/NavBar";
import './style.css'
import './mobstyle.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import wpp from './Whatsapp.png'
import telefone from './telefone.png'
import gmail from './gmail.png'
import outdoor from './ferro.png'
import Footer from "../../components/Footer/Footer";
import MediaQuery from 'react-responsive'
import Title from "../../components/title/Title"
 import NavLine from "../../components/navline/NavLine";   
const Contatos = () =>{
    
    AOS.init({
        duration: 800
    });
    return(
        <>
       
       <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab" />
       <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />
       
       
       <MediaQuery minWidth={1224}>
        
        <NavBar/>
        <NavLine/>
        <div className="contatos">
            <Title
            text="Como podemos ajudar?"/>
        <div className="contatos-square">
        <div className="contatos-line-1"></div>
        <div className="contatos-line-2"></div>
        <div className="contatos-square-title">Entre em contato conosco e tire suas dúvidas</div>
        <div className="contatos-wpp-gmail">
        <div className="contatos-wpp">
                <div className="contatos-info">
                    <a  href="https://wa.me/5592996179693"><img src={wpp} className="contatos-icon"/>+55 92 99617-9693</a></div>

                <div className="contatos-info">
                        
                    <a href="https://wa.me/5592985460075"><img src={wpp} className="contatos-icon"/>+55 92 98546-0075</a></div>
                <div className="contatos-info">
                    <a href="https://wa.me/5592996189432">  <img src={wpp} className="contatos-icon"/>+55 92 99618-9432</a></div>               

            </div>
            <div className="contatos-gmail">
            <div className="contatos-info">
                <a href='mailto:comercial@aliconcontainers.com.br'> <img src={gmail} className="contatos-icon-g"/>comercial@aliconcontainers.com.br</a>  
               </div>
               <div className="contatos-info">
                <a href='mailto:comercial2@aliconcontainers.com.br'> <img src={gmail} className="contatos-icon-g"/>comercial2@aliconcontainers.com.br</a>  
               </div>
               <div className="contatos-info">
                <a href='mailto:comercial3@aliconcontainers.com.br'> <img src={gmail} className="contatos-icon-g"/>comercial3@aliconcontainers.com.br</a>  
               </div>
        </div>
        </div>
       
        <div className="contatos-line-3"></div>
        <div className="contatos-line-4"></div>
        </div>
        </div>
        <div className="contatos-outdoor"><img src={outdoor}/></div>
        <Footer/>
        </MediaQuery>

        
        
        
        
        
        
        <MediaQuery maxWidth={1224}>
            <NavBar/>
            <NavLine/>
            <div className="mob-contatos">
            <Title
            text="Como podemos ajudar?"/>
        <div className="mob-contatos-square">
        <div className="mob-contatos-line-1"></div>
        <div className="mob-contatos-line-2"></div>
        <div className="mob-contatos-square-title">Entre em contato conosco e tire suas dúvidas</div>
        <div className="mob-contatos-wpp-gmail">
            <div className="contatos-wpp">
                <div className="mob-contatos-info">
                <a  href="https://wa.me/559299617-9693"><img src={wpp} className="mob-contatos-icon"/>+55 92 99617-9693</a>
                   </div>               
                     <div className="mob-contatos-info">
                      <a href="https://wa.me/559298546-0075"><img src={wpp} className="mob-contatos-icon"/>+55 92 98546-0075</a></div>
                <div className="mob-contatos-info">
                <a href="https://wa.me/5592996189432">  <img src={wpp} className="mob-contatos-icon"/>+55 92 99618-9432</a>
                   </div>
            </div>
            <div className="mob-contatos-gmail">
                <div className="mob-contatos-info">
                <a href='mailto:comercial@aliconcontainers.com.br'> <img src={gmail} className="mob-contatos-icon-g"/>comercial@aliconcontainers.com.br</a>  
               </div>
               <div className="mob-contatos-info">
                <a href='mailto:comercial2@aliconcontainers.com.br'> <img src={gmail} className="mob-contatos-icon-g"/>comercial2@aliconcontainers.com.br</a>  
               </div>
               <div className="mob-contatos-info">
                <a href='mailto:comercial3@aliconcontainers.com.br'> <img src={gmail} className="mob-contatos-icon-g"/>comercial3@aliconcontainers.com.br</a>  
               </div>
        </div>
        </div>
       
        <div className="mob-contatos-line-3"></div>
        <div className="mob-contatos-line-4"></div>
        </div>
        <div className="mob-contatos-outdoor"><img src={outdoor}/></div>

        </div>
        <Footer className="mob-contatos-footer"/>

        </MediaQuery>
        </>
    );
}
export default Contatos