import MediaQuery from "react-responsive";
import ServicosDesktop from "./ServicosDesktop";
import ServicosMobile from "./ServicosMobile";

const Servicos = () =>{
 return (
 <>
 <MediaQuery minWidth={1224}>
    <ServicosDesktop></ServicosDesktop>
 </MediaQuery>
 <MediaQuery maxWidth={1224}>
    <ServicosMobile></ServicosMobile>
 </MediaQuery>
 
 </>);   

}
export default Servicos