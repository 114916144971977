// Filename - components/SubMenu.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
	display: flex;
	color: #FFFFFF;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	height: 45px;
	text-decoration: none;
	font-size: 12px;
    font-family: "Zilla Slab";
    font-weight: 700;
    text-transform: uppercase;



`;

const SidebarLabel = styled.span`
	margin-left: 16px;
`;

const SidebarArrow = styled.span`
    display: flex;
    font-size:18px;
    align-items: flex-end;
    margin-left:220px;
    margin-top: -20px;

`;

const DropdownLink = styled(Link)`
	background: #941A1E;
	height: 25px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #f5f5f5;
	font-size: 12px;

`;

const SubMenu = ({ item }) => {
	const [subnav, setSubnav] = useState(false);

	const showSubnav = () => setSubnav(!subnav);
	return (
		<>
			<SidebarLink
				to={item.path}
				onClick={item.subNav && showSubnav}
			>
				<div>
                    <SidebarLabel>
                    {item.title}
					</SidebarLabel>
                    <SidebarArrow>
                    {item.icon}

                    </SidebarArrow>

                   </div>

				<div>
                    
					{item.subNav && subnav
						? item.iconOpened
						: item.subNav
						? item.iconClosed
						: null}

				</div>
			</SidebarLink>

			{subnav &&
				item.subNav.map((item, index) => {
					return (
						<DropdownLink
							to={item.path}
							key={index}
						>
							{item.icon}
							<SidebarLabel>
								{item.title}
							</SidebarLabel>
						</DropdownLink>
					);
				})}
		</>
	);
};

export default SubMenu;
