import { useState } from 'react';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SubMenu from './SubMenu';
import { SidebarData } from './SideBarData';
import Logo from '../Logo';
import React from 'react';


const Nav = styled.div`
    background: #F5F6F8;
    height: 47px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position:relative;

`;
 
const NavIcon = styled(Link)`
    margin-left: 2rem;
    font-size: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
`;
const SidebarLine = styled.span`
height:1px;
width:239px;
background: #FFFFFF;

`;
const SidebarNav = styled.nav
`
    background:rgba(124, 4, 8, 0.8);
    width: 239px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
    transition: 350ms;
    z-index: 100;
    
`;
 
const SidebarWrap = styled.div`
    width: 100%;
`;

const NavMobile=()=>{
    const [sidebar,setSidebar] = useState(false);
    const showSidebar = () =>  setSidebar(!sidebar);

    return(
        <>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab" />

       <Nav>

        <Logo 
        size="7rem"
        />
                    <NavIcon to="#">
                        <FaIcons.FaBars
                            size="15px"
                            color='#000'
                            onClick={showSidebar}
                        />
                    </NavIcon>
                  
                </Nav>
                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                        <NavIcon to="#">
                            <AiIcons.AiOutlineClose
                                color='#FFFFFF'
                                size="15px"
                                onClick={showSidebar}
                            />
                        </NavIcon>
                       {SidebarData.map((item, index) => {
                            return (
                                <SubMenu
                                    item={item}
                                    
                                    key={index}
                                />
                            );

                        })}
                    </SidebarWrap>
                </SidebarNav>
        </>
    );

}
export default NavMobile