import Rectangle from "../../../components/rectangle/Rectangle";
import RectangleCar from "../../../components/rectangle/RectangleCar";
import RectangleCar2 from "../../../components/rectangle/RectangleCar2";

import NavBar from "../../../components/navbar/NavBar"
import NavLine from '../../../components/navline/NavLine'
import Footer from "../../../components/Footer/Footer";
import Title from "../../../components/title/Title";
import ButtonRed from'../../../components/button/ButtonRed'
import img2 from "./locmodh4.png"
import img1 from "./locmodh5.png"
import img3 from "./locmodh3.png"
import img4 from "./locmodh7.png"
import img7 from "./locmodh7.png"
import caresc1 from "./caresc1.png"
import caresc2 from "./caresc2.png"
import mhab1 from "./mhab1.png"
import mhab2 from "./mhab2.png"
import mhab3 from "./mhab3.png"
import mhab4 from "./mhab4.png"

import AOS from 'aos';
import 'aos/dist/aos.css';
import './style.css'
import MediaQuery from 'react-responsive'
import RectangleMob from "../../../components/rectangle/RectangleMob";
const LocModulosH = () =>{
    AOS.init({
        duration: 800
    });
    return(
        <>

        <MediaQuery minWidth={1224}>
        <NavBar/>
        <NavLine/>

    
<div className="loc-mod-h">
<Title
    text="LOCAÇÃO DE MÓDULOS HABITÁVEIS"
    />
<div className='servicos-subtitle'>A Alicon oferece a locação dos seguintes módulos habitáveis: </div>


<Rectangle
title ="MóDULO HABITÁVEL 20/40"
text ="  Módulos Habitáveis Similar a Contêiner 20 e 40.
Tipo Almoxarifado com Teto Revestido e Instalações Elétricas, conforme NR10 (janela opcional)."
img = {img1}
/>

<RectangleCar
title ="MóDULO HAB ESC C/BH 20/40"
text =" Módulos Habitáveis Similar Contêiner 20 e 40. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc,  com Banheiro, conforme NR 10, 18 e 24."
img1 = {img2}
img2 = {caresc1}
img3 = {caresc2}

/>

<Rectangle
title ="MóDULO stand de vendas"
text =" Módulos Habitáveis Similar Contêiner 20´ e 40’.
Climatizado - Tipo Stand para Vendas, sem Banheiro, com Painel Lateral em Vidro Temperado, conforme NR 18."
img = {img3}/>

<Rectangle
title ="MóDULO HAB BANHEIRO 20/40"
text ="  Módulos Habitáveis Similar Contêiner 20 e 40. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc,  com Banheiro, conforme NR 10, 18 e 24."
img = {img4}/>


<RectangleCar2
title ="MóDULO HAB 10"
text =" Módulos Habitáveis Similar Contêiner 10. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc, conforme NR 10, 18 e 24."
img1 = {mhab1}
img2 ={mhab2}
img3 ={mhab3}
img4 ={mhab4}
/>

<ButtonRed/>
</div>
<Footer/>
        </MediaQuery>
     
       <MediaQuery maxWidth={1224}>
       <NavBar/>
        <NavLine/>
<div className="mob-locmod-h">
    <Title
    text="LOCAÇÃO DE MÓDULOS HABITÁVEIS"
    />
    <div className='mob-servicos-subtitle'>A Alicon oferece a locação dos seguintes módulos habitáveis: </div>

    <RectangleMob
title ="MóDULO HABITÁVEL 20/40"
text ="  Módulos Habitáveis Similar a Contêiner 20 e 40.
Tipo Almoxarifado com Teto Revestido e Instalações Elétricas, conforme NR10 (janela opcional)."
img = {img1}
/>


<RectangleCar
title ="MóDULO HAB ESC C/BH 20/40"
text =" Módulos Habitáveis Similar Contêiner 20 e 40. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc,  com Banheiro, conforme NR 10, 18 e 24."
img1 = {img2}
img2 = {caresc1}
img3 = {caresc2}

/>

<RectangleMob
title ="MóDULO stand de vendas"
text =" Módulos Habitáveis Similar Contêiner 20´ e 40’.
Climatizado - Tipo Stand para Vendas, sem Banheiro, com Painel Lateral em Vidro Temperado, conforme NR 18."
img = {img3}/>

<RectangleMob
title ="MóDULO HAB BANHEIRO 20/40"
text ="  Módulos Habitáveis Similar Contêiner 20 e 40. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc,  com Banheiro, conforme NR 10, 18 e 24."
img = {img4}/>


<RectangleCar2
title ="MóDULO HAB 10"
text =" Módulos Habitáveis Similar Contêiner 10. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc, conforme NR 10, 18 e 24."
img1 = {mhab1}
img2 ={mhab2}
img3 ={mhab3}
img4 ={mhab4}
/>

<ButtonRed/>

</div>
<Footer/>
       </MediaQuery>
        </>
    );
}
export default LocModulosH