import { NavLink } from "react-router-dom";
import './BlueSquare.css'
import arrow from '../../imagens/ArrowRight.png'
const BlueSquare = (props)=>{
    return(
        <div>
            <div className="bl_img"><img className="config_img"src={props.img}alt="imagem que ilustra o serviço"></img></div>
            <div className="container_blue">
                <div className="title-square">{props.title}</div>
                <div className="text-square">{props.text}</div>
                <NavLink to={props.pagina}>
                    <button className="button">
                        <div>Saiba Mais</div>
                        <img alt="seta"src={arrow}></img>                   
                    </button>
                </NavLink>
                
            </div>
        </div>
    );
}
export default BlueSquare