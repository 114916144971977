import MediaQuery from "react-responsive";
import Footer from "../../../components/Footer/Footer";
import ButtonRed from "../../../components/button/ButtonRed";
import NavBar from "../../../components/navbar/NavBar";
import Title from "../../../components/title/Title"
import "./style.css"
import './stylemob.css'
import img1 from "./trans1.png"
import img2 from "./trans2.png"
import img3 from "./trans3.png"
import img4 from "./trans4.png"
import NavLine from "../../../components/navline/NavLine";

const Transporte=()=>{
    return(

        <>
        <MediaQuery minWidth={1224}>
        <NavBar/>
        <NavLine/>
        <div className="transporte">
         <Title
         text="SERVIÇOS DE TRANSPORTE"
         />   
       
                    <div className='trans-row'>
                        <div className='trans-imgs'> < img className='config_img' src={img1}></img></div>
                        <div className='trans-bluecont'>
                            <div className="trans-line-1"></div>
                            <div className="trans-title">Mobilização de container </div>
                            <div className='trans-text'>
                                <div> <b>Trajetos:</b> <br/>Manaus x Boa vista  <br/> Manaus x Rio Preto da Eva  <br/>Manaus x Silves</div>
                                <div className="trans-line-vert"/>
                                <div>Manaus x Itacoatiara<br/> Manaus x Presidente Figueredo <br/> Manaus x Manacapuru <br/> Manaus x Iranduba </div>
                            </div>
                            <div className="trans-line-3"></div>
                        </div>
                    </div>
                    <div className='trans-row'>
                        <div className='trans-redcont'>
                            <div className="trans-line-1"></div> 
                            <div className="trans-title">desMobilização de container</div>

                            <div className='trans-text'>
                                <div> <b>Trajetos:</b> <br/>Boa vista x Manaus <br/> Rio Preto da Eva x Manaus <br/> Silves x Manaus</div>
                                <div className="trans-line-vert"/>
                                <div>Itacoatiara x Manaus <br/> Presidente Figueredo x Manaus<br/> Manacapuru x Manaus <br/>Iranduba x Manaus</div>
                            </div>
                            <div className="trans-line-3"></div>
                        </div>
                        <div className='trans-imgs'> < img className='config_img' src={img2}></img></div>
                    </div>
                    <div className='trans-row'>
                        <div className='trans-imgs'> < img className='config_img' src={img4}></img></div>
                        <div className='trans-bluecont'>
                            <div className="trans-line-1"></div>
                            <div className="trans-title">Transporte de Container</div>
                            <div className='trans-text'>
                                <div> <b>Trajetos:</b> <br/>Alicon x Portos em Manaus<br/> Portos em Manaus x Alicon <br/>Alicon x Portox Depot<br/> Alicon x Cliente<br/> Porto x Depot</div>
                                <div className="trans-line-vert"/>
                                <div>Porto x Cliente<br/>Cliente x Porto<br/>Porto x Cliente x Depot sem free <br/>time<br/>
Porto x Cliente x Depot com free<br/> time de 02 dias

</div>
                            </div>
                            <div className="trans-line-3"></div>
                        </div>
                    </div>
                    <div className='trans-row'>
                        <div className='trans-redcont'>
                            <div className="trans-line-1"></div> 
                            <div className="trans-title">outros serviços</div>

                            <div className='trans-text'>
                                <div className="texxt"> Viagem frustrada do conjunto transportador Cavalo Munck e Carreta porta contêiner para entrega de contêiner 20´
</div>
                                <div className="trans-line-vert"/>
                                <div className="texxt">Viagem frustrada do conjunto transportador Cavalo Munck e Carreta porta contêiner para entrega de contêiner 40´</div>
                            </div>
                            <div className="trans-line-3"></div>
                        </div>
                        <div className='trans-imgs'> < img className='config_img' src={img3}></img></div>
                    </div>
         
        <ButtonRed/>
        </div>
        <Footer/>
        </MediaQuery>

        <MediaQuery maxWidth={1224}>
        <NavBar/>
        <NavLine/>
        <div className="transporte">
         <Title
         text="SERVIÇOS DE TRANSPORTE"
         />   
       
                    <div className='mob-trans-row'>
                        <div className='mob-trans-bluecont'>
                        <div className="mob-trans-line-1"></div>
                            <div className="mob-trans-title">Mobilização de container </div>
                            <div className='mob-trans-text'>
                                <div> <b>Trajetos:</b> <br/>Manaus x Boa vista  <br/> Manaus x Rio Preto da Eva  <br/>Manaus x Silves</div>
                                <div className="mob-trans-line-vert"/>
                                <div>Manaus x Itacoatiara<br/> Manaus x Presidente Figueredo <br/> Manaus x Manacapuru <br/> Manaus x Iranduba </div>
                            </div>
                            <div className="mob-trans-line-3"></div>
                        </div>
                    <div className='mob-trans-imgs'> < img className='config_img' src={img1}></img></div>

                    </div>
                    <div className='mob-trans-row'>
                        <div className='mob-trans-redcont'>
                        <div className="mob-trans-line-1"></div> 
                            <div className="mob-trans-title">desMobilização de container</div>

                            <div className='mob-trans-text'>
                                <div> <b>Trajetos:</b> <br/>Boa vista x Manaus <br/> Rio Preto da Eva x Manaus <br/> Silves x Manaus</div>
                                <div className="mob-trans-line-vert"/>
                                <div>Itacoatiara x Manaus <br/> Presidente Figueredo x Manaus<br/> Manacapuru x Manaus <br/>Iranduba x Manaus</div>
                            </div>
                            <div className="mob-trans-line-3"></div>
                        </div>
                        <div className='mob-trans-imgs'> < img className='config_img' src={img2}></img></div>
                    </div>
                    <div className='mob-trans-row'>
                        <div className='mob-trans-bluecont'>
                        <div className="mob-trans-line-1"></div>
                            <div className="mob-trans-title">Transporte de Container</div>
                            <div className='mob-trans-text'>
                                <div> <b>Trajetos:</b> <br/>Alicon x Portos em Manaus<br/> Portos em Manaus x Alicon <br/>Alicon x Portox Depot<br/> Alicon x Cliente<br/> Porto x Depot</div>
                                <div className="mob-trans-line-vert"/>
                                <div>Porto x Cliente<br/>Cliente x Porto<br/>Porto x Cliente x Depot sem free <br/>time<br/>
Porto x Cliente x Depot com free<br/> time de 02 dias

</div>
                            </div>
                            <div className="mob-trans-line-3"></div>
                        </div>
                        <div className='mob-trans-imgs'> < img className='config_img' src={img4}></img></div>

                        </div>
                    <div className='mob-trans-row'>
                        <div className='mob-trans-redcont'>
                        <div className="mob-trans-title">outros serviços</div>

                        <div className='mob-trans-text'>
    <div className="mob-texxt"> Viagem frustrada do conjunto transportador Cavalo Munck e Carreta porta contêiner para entrega de contêiner 20´
</div>
    <div className="mob-trans-line-vert"/>
    <div className="mob-texxt">Viagem frustrada do conjunto transportador Cavalo Munck e Carreta porta contêiner para entrega de contêiner 40´</div>
</div>
<div className="trans-line-3"></div>
                        </div>
                        <div className='mob-trans-imgs'>< img className='config_img' src={img3}></img></div>
                    </div>
         
        <ButtonRed/>
        </div>
        <Footer/>
        </MediaQuery>
        </>
    );
}
export default Transporte