import NavBar from '../../../components/navbar/NavBar';
import Footer from '../../../components/Footer/Footer';
import MediaQuery from 'react-responsive'
import ButtonRed from '../../../components/button/ButtonRed';
import NavLine from '../../../components/navline/NavLine';
import Title from '../../../components/title/Title';
import img1 from './manu1.png'
import img2 from './manu2.png'
import '../fabricacao/stylemob.css'
import './style.css'

const Manutencao =()=>{
return(
<>
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />
            <MediaQuery minWidth={1224}>
                <NavBar />
                <NavLine/>
                <div className='mob-fab'>
                    <Title
                    text="mANUTENÇÃO DE CONTAINER REEFER"/>
                <div className='quadro'>
                    <div className='row'>
                        <div className='imgs'> < img className='config_img' src={img1}></img></div>
                        <div className='bluecont'>
                            <div className="fab-line-1"></div> <div class="fab-line-2"></div>
                            <div className='fab_text'>Alicon pOSSUI UMA EQUIPE ESPECIALIZADA PARA A MANUTENÇÃO DO SEU CONTAINER REEFER</div>
                            <div className="fab-line-3"></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='redcont'>
                            <div className="fab-line-1"></div> <div class="fab-line-2"></div>
                            <div className='fab_text'> por isso nós  montamos e personalizamos o container para que fique do jeito que você imaginou
SE VOCÊ NecessiTA DE UM reparo no seu container, entre em contato com a alicon para que possamos solucionar seu problema
 </div>
                            <div className="fab-line-3"></div>
                        </div>
                        <div className='imgs'> < img className='config_img' src={img2}></img></div>
                    </div>
                </div>
                <ButtonRed/>   

                </div>             
                <Footer/>
            </MediaQuery>
            <MediaQuery maxWidth={1224}>
            <NavBar/>
                <NavLine/>
                <div className='mob-fab'>
                <Title
                    text="mANUTENÇÃO DE CONTAINER REEFER"/>
                    <div className='mob-fab-row'>
                        <div className='mob-fab-bluecont'>
                            <div className="mob-fab-line-1"></div> <div class="mob-fab-line-2"></div>
                            <div className='mob-fab_text'>Alicon pOSSUI UMA EQUIPE ESPECIALIZADA PARA A MANUTENÇÃO DO SEU CONTAINER REEFER</div>
                            <div className="mob-fab-line-3"></div>
                        </div>
                        <div className='mob-imgs'> < img className='config_img' src={img1}></img></div>

                        <div className='mob-fab-redcont'>
                            <div className="mob-fab-line-1"></div> <div class="mob-fab-line-2"></div>
                            <div className='mob-man-text'>  por isso nós  montamos e personalizamos o container para que fique do jeito que você imaginou
                            SE VOCÊ NecessiTA DE UM reparo no seu container, entre em contato com a alicon para que possamos solucionar seu problema </div>
                            <div className="mob-fab-line-3"></div>
                        </div>
                        <div className='mob-imgs'> < img className='config_img' src={img2}></img></div>
                    </div>
                    <ButtonRed/>
                </div>
                <Footer/>
            </MediaQuery>
</>

);
}
export default Manutencao