import NavBar from '../../../components/navbar/NavBar'
import './style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from './locmaquinas1.png'
import img2 from './locmaquinas2.png'
import img3 from './locmaquinas3.png'
import img4 from './locmaquinas4.png'
import img5 from './locmaquinas5.png'
import img6 from './locmaquinas2.png'
import Footer from '../../../components/Footer/Footer';
import './style.css'
import './stylemob.css'
import NavLine from "../../../components/navline/NavLine"
import ButtonRed from "../../../components/button/ButtonRed"
import Title from "../../../components/title/Title"
import MediaQuery from 'react-responsive'
const LocMaquinass = () =>{
  
      AOS.init({
        duration: 800
    });
    return(
        <>
         <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab" />
         <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />
    <MediaQuery minWidth={1224}>
    <NavBar/>
    <NavLine/>
        <div className='mob-loc-maq'>
            <Title 
            text="Locação de máquinas e equipamentos"
            />
        <div className='servicos-subtitle'>A Alicon oferece a locação de máquinas e equipamentos: </div>
        <div className='fila'> 
        <div className='retangulo'><img src={img1}className='config_img'/> <div className='gray_box'><div  className='subtitle'>EMPILHADEIRA</div></div></div>
        <div className='retangulo'><img src={img2}className='config_img'/> <div className='gray_box'><div  className='subtitle'>CARRETA</div></div></div>
        <div className='retangulo'><img src={img3}className='config_img'/> <div className='gray_box'><div  className='subtitle'>MUNK</div></div></div>
        </div>
        <div className='fila'> 
        <div className='retangulo'><img src={img4}className='config_img'/> <div className='gray_box'><div  className='subtitle'>CAVALO MECÂNICO</div></div></div>
        <div className='retangulo'><img src={img6}className='config_img'/> <div className='gray_box'><div  className='subtitle'>CONJUNTO TRANSPORTADOR</div></div></div>
         <div className='img'></div>
        </div>
        <ButtonRed/>
        </div>
        <Footer/>
    </MediaQuery>
    <MediaQuery maxWidth={1224}>
    <NavBar/>
    <NavLine/>
      <div className='mob-loc-maq'>
      <Title 
            text="Locação de máquinas e equipamentos"
            />
    <div className='mob-servicos-subtitle'>A Alicon oferece a locação de máquinas e equipamentos: </div>

        <div className='mob-fila'> 
        <div className='mob-retangulo'><img src={img1}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>EMPILHADEIRA</div></div></div>
        <div className='mob-retangulo'><img src={img2}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>CARRETA</div></div></div>
        <div className='mob-retangulo'><img src={img3}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>MUNK</div></div></div>
        <div className='mob-retangulo'><img src={img4}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>Cavalo mecânico</div></div></div>
        <div className='mob-retangulo'><img src={img6}className='config_img'/> <div className='mob-gray_box'><div  className='mob-subtitle'>CONJUNTO TRANSPORTADOR</div></div></div>
        </div>
        <ButtonRed/>

        </div>
        <Footer/>
    </MediaQuery>
        </>
    );

}
export default LocMaquinass