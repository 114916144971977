import NavBar from '../../../components/navbar/NavBar';
import MediaQuery from 'react-responsive';
import './style.css'
import Rectangle from '../../../components/rectangle/Rectangle';
import RectangleCar from "../../../components/rectangle/RectangleCar";
import RectangleCar2 from "../../../components/rectangle/RectangleCar2";

import img1 from "./loccont1.png"
import img2 from "./loccont2.png"
import img3 from "./loccont3.png"
import img4 from "./loccont4.png"
import img5 from "./loccont5.png"
import img6 from "./locmodh4.png"
import img7 from "./locmodh5.png"
import img8 from "./locmodh3.png"
import img9 from "./locmodh7.png"
import caresc1 from "./caresc1.png"
import caresc2 from "./caresc2.png"
import mhab1 from "./mhab1.png"
import mhab2 from "./mhab2.png"
import mhab3 from "./mhab3.png"
import mhab4 from "./mhab4.png"
import Footer from '../../../components/Footer/Footer';
import RectangleMob from "../../../components/rectangle/RectangleMob"
import ButtonRed from '../../../components/button/ButtonRed';
import NavLine from '../../../components/navline/NavLine';
import Title from '../../../components/title/Title';
import BlueSquareLine from '../../../components/bluesquareline/BlueSquareLine';
import BlueSquareLineMob from '../../../components/bluesquareline/BlueSquareLineMob';
const VendaCont =()=>{
return(
    <>
    <MediaQuery minWidth={1224}>
    <NavBar/> 
    <NavLine/>
        <div className='venda-cont'>
          <Title
          text="Venda de Containers"
          />
        <div className='servicos-subtitle'>A Alicon disponibiliza da venda de vários modelos de containers além de personaliza-los do jeito que você deseja: </div>

    
    <BlueSquareLine
        title ="Container DRY 20"
        textbold="Tamanho externo"
        text1= "Comprimento:  6.058m  "
        text2="Largura : 2.438m "
        text3="Altura: 2.591m "
        text4="Capacidade máxima: 24.000 kg."

        textbold2="Tamanho interno"
        text5= "Comprimento: 5.900 m"
        text6="Largura:2.350m "
        text7="Altura:2.393m"
        text8="Capacidade cúbica: 33,2m."
        img={img1}
    ></BlueSquareLine>

    <BlueSquareLine
        title ="Container DRY 40"
        textbold="Tamanho externo"
        text1= "Comprimento: 12.192m"
        text2="Largura : 2.438m "
        text3="Altura: 2.591m "
        text4="Capacidade máxima: 26.930kg."

        textbold2="Tamanho interno"
        text5= "Comprimento: 12.032m "
        text6="Largura:2.350m "
        text7="Altura:2.393m"
        text8="Capacidade cúbica: 67,7m"
        img={img2}/>
    
<BlueSquareLine
        title ="Container reefer 20"
        textbold="Tamanho externo"
        text1= "Comprimento: 6.058m"
        text2="Largura: 2,438m "
        text3="Altura: 2.591m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 5.444 mm"
        text6="Largura: 2.294 mm "
        text7="Altura: 2.276 mm"
        img={img3}
    ></BlueSquareLine>

<BlueSquareLine
        title ="Container reefer 40 RH"
        textbold="Tamanho externo"
        text1= "Comprimento: 12.192m"
        text2="Largura: 2,438m "
        text3="Altura: 2.590m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 11.561 mm "
        text6="Largura: 2.268 mm "
        text7="Altura: 2.249 mm"
        img={img4}
    ></BlueSquareLine>

<BlueSquareLine
        title =" CONTaineR HC 40"
        textbold="Tamanho externo"
        text1= "Comprimento: 12,192m"
        text2="Largura: 2,438m "
        text3="Altura: 2,896m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 12,03m "
        text6="Largura: 2,352m "
        text7="Altura: 2,695m"
        img={img5}
    ></BlueSquareLine>
   

   <Rectangle
title ="MóDULO HABITÁVEL 20/40"
text ="  Módulos Habitáveis Similar a Contêiner 20 e 40.
Tipo Almoxarifado com Teto Revestido e Instalações Elétricas, conforme NR10 (janela opcional)."
img = {img7}
/>

<RectangleCar
title ="MóDULO HAB ESC C/BH 20/40"
text =" Módulos Habitáveis Similar Contêiner 20 e 40. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc,  com Banheiro, conforme NR 10, 18 e 24."
img1 = {img6}
img2 = {caresc1}
img3 = {caresc2}

/>

<Rectangle
title ="MóDULO stand de vendas"
text =" Módulos Habitáveis Similar Contêiner 20´ e 40’.
Climatizado - Tipo Stand para Vendas, sem Banheiro, com Painel Lateral em Vidro Temperado, conforme NR 18."
img = {img8}/>

<Rectangle
title ="MóDULO HAB BANHEIRO 20/40"
text ="  Módulos Habitáveis Similar Contêiner 20 e 40. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc,  com Banheiro, conforme NR 10, 18 e 24."
img = {img9}/>
<RectangleCar2
title ="MóDULO HAB 10"
text =" Módulos Habitáveis Similar Contêiner 10. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc, conforme NR 10, 18 e 24."
img1 = {mhab1}
img2 ={mhab2}
img3 ={mhab3}
img4 ={mhab4}
/>

    
    <ButtonRed/>
    </div>
    <Footer/>

    </MediaQuery>

    <MediaQuery maxWidth={1224}>
      <NavBar/>
      <NavLine/>
        <div className='venda-cont'>
        <Title
          text="Venda de Containers"
          />
        <div className='mob-servicos-subtitle'>A Alicon disponibiliza da venda de vários modelos de containers além de personaliza-los do jeito que você deseja: </div>

        <BlueSquareLineMob
        title ="Container DRY 20"
        textbold="Tamanho externo"
        text1= "Comprimento:  6.058m  "
        text2="Largura : 2.438m "
        text3="Altura: 2.591m "
        text4="Capacidade máxima: 24.000 kg."

        textbold2="Tamanho interno"
        text5= "Comprimento: 5.900 m"
        text6="Largura:2.350m "
        text7="Altura:2.393m"
        text8="Capacidade cúbica: 33,2m."
        img={img1}
    ></BlueSquareLineMob>

    <BlueSquareLineMob
        title ="Container DRY 40"
        textbold="Tamanho externo"
        text1= "Comprimento: 12.192m"
        text2="Largura : 2.438m "
        text3="Altura: 2.591m "
        text4="Capacidade máxima: 26.930kg."

        textbold2="Tamanho interno"
        text5= "Comprimento: 12.032m "
        text6="Largura:2.350m "
        text7="Altura:2.393m"
        text8="Capacidade cúbica: 67,7m"
        img={img2}/>
        
        
        <BlueSquareLineMob
        title ="Container reefer 20"
        textbold="Tamanho externo"
        text1= "Comprimento: 6.058m"
        text2="Largura: 2,438m "
        text3="Altura: 2.591m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 5.444 mm"
        text6="Largura: 2.294 mm "
        text7="Altura: 2.276 mm"
        img={img3}
    ></BlueSquareLineMob>

<BlueSquareLineMob
        title ="Container reefer 40 RH"
        textbold="Tamanho externo"
        text1= "Comprimento: 12.192m"
        text2="Largura: 2,438m "
        text3="Altura: 2.590m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 11.561 mm "
        text6="Largura: 2.268 mm "
        text7="Altura: 2.249 mm"
        img={img4}
    />

<BlueSquareLineMob
        title =" CONTaineR HC 40"
        textbold="Tamanho externo"
        text1= "Comprimento: 12,192m"
        text2="Largura: 2,438m "
        text3="Altura: 2,896m "

        textbold2="Tamanho interno"
        text5= "Comprimento: 12,03m "
        text6="Largura: 2,352m "
        text7="Altura: 2,695m"
        img={img5}
   />

<RectangleMob
title ="MóDULO HABITÁVEL 20/40"
text ="  Módulos Habitáveis Similar a Contêiner 20 e 40.
Tipo Almoxarifado com Teto Revestido e Instalações Elétricas, conforme NR10 (janela opcional)."
img = {img7}
/>

<RectangleCar
title ="MóDULO HAB ESC C/BH 20/40"
text =" Módulos Habitáveis Similar Contêiner 20 e 40. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc,  com Banheiro, conforme NR 10, 18 e 24."
img1 = {img6}
img2 = {caresc1}
img3 = {caresc2}

/>

<RectangleMob
title ="MóDULO stand de vendas"
text =" Módulos Habitáveis Similar Contêiner 20´ e 40’.
Climatizado - Tipo Stand para Vendas, sem Banheiro, com Painel Lateral em Vidro Temperado, conforme NR 18."
img = {img8}/>

<RectangleMob
title ="MóDULO HAB BANHEIRO 20/40"
text ="  Módulos Habitáveis Similar Contêiner 20 e 40. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc,  com Banheiro, conforme NR 10, 18 e 24."
img = {img9}/>
<RectangleCar2
title ="MóDULO HAB 10"
text =" Módulos Habitáveis Similar Contêiner 10. 
Climatizado - Tipo Escritório, Alojamento, Vestiário, Refeitório e etc, conforme NR 10, 18 e 24."
img1 = {mhab1}
img2 ={mhab2}
img3 ={mhab3}
img4 ={mhab4}
/>
    
    <ButtonRed/>
    </div>
    <Footer/>

    </MediaQuery>
    </>
);
}
export default VendaCont