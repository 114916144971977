import AOS from 'aos';
import 'aos/dist/aos.css';
import Carrossel from '../../imagens/PaginaInicial/Carrossel.png'
import '../principal/inicial.css'
import './stylemob.css'
import Cont1 from '../../imagens/PaginaInicial/cont1.png'
import Cont2 from '../../imagens/PaginaInicial/inicial2.png'
import Cont3 from '../../imagens/PaginaInicial/inicial3.png'
import arrow from '../../imagens/PaginaInicial/arrow.png'

import circle from '../../imagens/PaginaInicial/circle.png'
import circleb from '../../imagens/PaginaInicial/circleb.png'


import samsung from '../../imagens/PaginaInicial/samsung.png'
import honda from '../../imagens/PaginaInicial/honda.png'
import defesa from '../../imagens/PaginaInicial/defesa.png'
import direcional from '../../imagens/PaginaInicial/direcional.png'
import taboca from '../../imagens/PaginaInicial/taboca.png'
import mineiraco from '../../imagens/PaginaInicial/mineiracao.png'

import NavBar from '../../components/navbar/NavBar';
import Footer from '../../components/Footer/Footer'
import Ouvidoria from './Ouvidoria';
import MediaQuery from 'react-responsive';
import { Link} from 'react-router-dom';

import { HiArrowLongDown } from "react-icons/hi2";
import NavMobileP from '../../components/navbar/mobile/NavMobilePrin';




const Inicial = () => {
    AOS.init({
        duration: 800
    });

    return (

        <>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto" />

            <MediaQuery minWidth={1224}>

                <NavBar />
                <div className='botao-orcamento-principal-p'>
                    <Link to="/contatos" className='nav-link'>
                   <div className="botao-orcamento-principal">
                       <div>SOLICITE SEU ORÇAMENTO</div>
                    </div>
                    </Link> 
                </div>
               
                
            
                <div className="imgbk">
                    
                
               <div><img alt="foto principal com caminhoes e containers"className="fotocontainer" src={Carrossel}></img></div> 

             
                </div>
               
                <div className='container_side1'>
                    <div className='imgcont'>< img className='config_imgcont' alt='containers' src={Cont1}></img></div>
                    <div className='bloco_azul'>
                        <div className="principal-w-reta pline-vert" data-aos="fade-down"></div>

                        <div className="titlebl">A Empresa</div>
                        <div className="principal-text">A ALICON é uma empresa amazonense com mais de 20 anos de experiência no mercado. Oferecemos soluções em contêineres e afins.</div>
                        <div className='pri-line2'></div>
                        <div className='pri-line1'></div>
                        <div className="arrow" data-aos="fade-down"><img alt="seta apontando para baixo"src={arrow}></img></div>
                            
                        <div className="elipse" ><img alt="circulo"src={circle}></img></div>


                    </div>

                </div>
                <div className='container_side'>
                    <div className='bloco_vermelho'>
                        <div className="principal-w-reta pline-vert" data-aos="fade-down"></div>

                        <div className="titlebl">Nossa Missão</div>
                        <div className="principal-text">Prestar serviços de locação de equipamento com uso temporário,<br></br> praticidade e eficiência.</div>

                        <div className='pri-line2'></div>

                        <div className='pri-line1'></div>
                        <div className="arrow" data-aos="fade-down"><img alt="seta apontando para baixo" src={arrow}></img></div>

                        <div className="elipse" ><img alt="circulo"src={circleb}></img></div>

                    </div>
                    <div className='imgcont'><img className='config_imgcont' alt='containers' src={Cont2}></img></div>
                </div>
                <div className='container_side'>
                    <div className='imgcont'><img className='config_imgcont' alt='containers' src={Cont3}></img></div>
                    <div className='bloco_azul'>
                        <div className="principal-w-reta pline-vert" data-aos="fade-down"></div>

                        <div className="titlebl">Visão e Valores</div>
                        <div className="principal-text">Nossa visão é inovar com soluções inteligentes em equipamentos, mantendo a marca como referência e colaborar na conservação do meio ambiente.</div>
                        <div className='pri-line2'></div>

                        <div className='pri-line1'></div>
                        <div className="arrow" data-aos="fade-down"><img alt="seta apontando para baixo"src={arrow}></img></div>

                        <div className="elipse" ><img alt="circulo"src={circle}></img></div>


                    </div>

                </div>
                <div className='bk'>
                    <div className="reta-preta-i" data-aos="fade-down"></div>

                    <div className="txt-titulo-loc">Localização</div>

                    <div className="txtunidade">Unidade Manaus</div>
                    <div className="maps " data-aos="fade-right">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.977449395301!2d-59.91151498961163!3d-3.1006508968619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x926c059e93d6b04d%3A0xb2d1fc33f794c626!2sAlicon%20Terminal!5e0!3m2!1spt-BR!2sbr!4v1694099856433!5m2!1spt-BR!2sbr"
                          title='loc-unidade-manaus'  
                          width="1065" height="296" loading="lazy" className='mapsgoogle anime'></iframe>
                    </div>
                    <div className="txt-endereco">Av.Cosme Ferreira</div>

                    <div className="teste">
                        <div className="sidebox">
                        <div className="reta-preta-i" data-aos="fade-down"></div>

                            <div className="txt-outras-unidades">Outras<br></br>Unidades</div>
                        </div>

                        <div className="mapinhas"  >
                            <div className="txtunidade" >Unidade Boa Vista</div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63763.835020336664!2d-60.756580276970794!3d2.7451776884092114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8d93039c1b1811d3%3A0xf3b6ed357a6edf3a!2sTERMINAL%20ALICON%20BOA%20VISTA!5e0!3m2!1spt-BR!2sbr!4v1713344744111!5m2!1spt-BR!2sbr"
                                width="701"
                                height="200"
                                loading="lazy"
                                title='loc-unidade-boavista'
                                className='mapsgoogle' data-aos="fade-right"></iframe>
                            <div className="txt-endereco">Distrito Industrial Gov. Aquilino Mota Duarde.  </div>

                            <div className="txtunidade">Unidade Itajaí</div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.2973184016373!2d-48.71396838912617!3d-26.894057676560944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8ccd7d5b83b7d%3A0xd74627c8f51eb39e!2sR.%20Francisco%20Reis%2C%20650%20-%20Cordeiros%2C%20Itaja%C3%AD%20-%20SC%2C%2088311-735!5e0!3m2!1spt-BR!2sbr!4v1713345431136!5m2!1spt-BR!2sbr"
                                width="701"
                                height="200"
                                loading="lazy"
                                title='loc-unidade-itajai'
                                className='mapsgoogle' data-aos="fade-right"
                            ></iframe>
                            <div className="txt-endereco">Rua Francisco Reis, 650, Sala 03 Patio</div>

                        </div>

                    </div>
                    <div className="reta-preta-i" data-aos="fade-down"></div>
                    <div className="txt-titulo-loc">Nossos Principais Clientes</div>

                    <div className='img_logos'>
                        <img alt='logo-samsung' src={samsung} className='samsung'></img>
                        <img  alt='logo-honda' src={honda} className='honda'></img>
                        <img  alt='logo-ministerio'src={defesa} className='ministerio'></img>
                    </div>
                    <div className='img_logos'>
                        <img  alt='logo-direcional' src={direcional} className='direcional'></img>
                        <img   alt='logo-mineiraço'src={mineiraco} className='mineiracao'></img>
                        <img  alt='logo-taboca' src={taboca} className='taboca'></img>
                    </div>
                    <div className="reta-preta-i" data-aos="fade-down"></div>

                <div className="txt-titulo-loc">Ouvidoria</div>
                <div className='txt-ouvidoria'>
                    Deixe-nos saber sua opinião, sugestão ou ideias! <br />
                    Envie uma mensagem sobre o que deseja falar:
                </div>
                <Ouvidoria />
                </div>
                
                <Footer></Footer>
            </MediaQuery>

            <MediaQuery maxWidth={1224}>

                <NavMobileP/>
                <div className='mob-botao-orcamento-principal-p'>
                    <Link to="/contatos" className='nav-link'>
                   <div className="mob-botao-orcamento-principal">
                       <div>SOLICITE SEU ORÇAMENTO</div>
                    </div>
                    </Link> 
                </div>
                <div className="mob-imgbk"><img alt="containers e caminhoes"className="fotocontainer" src={Carrossel}></img></div>
                <div className='mob-in-information'>
                <div className='mob-bloco_azul'>
                    <div className="mob-titlebl-a">A Empresa                    
                    <div className="mob-principal-w-reta" data-aos="fade-right"></div>
</div>

                    <div className="mob-principal-text">A ALICON é uma empresa amazonense com mais de 18 anos de <br></br> experiência no mercado. Oferecemos soluções em contêineres e afins.</div>
                    <div className='mob-pri-line2'></div>
                    <div className='mob-pri-line1'></div>
                    <div className="mob-arrow" data-aos="fade-down"><HiArrowLongDown color='white'size="24px" /></div>

                    <div className="mob-elipse" ><img alt="circulo"src={circle}></img></div>


                </div>
                <div className='mob-imgcont'><img alt="containers"className='config_imgcont' src={Cont1}></img></div>

                <div className='mob-bloco_vermelho'>
                    <div className="mob-titlebl">Nossa Missão
                    <div className="mob-principal-w-reta" data-aos="fade-right"></div>
</div>

                    <div className="mob-principal-text">Prestar serviços de locação de equipamento com uso temporário,<br></br> praticidade e eficiência.</div>
                    <div className='mob-pri-line2'></div>
                    <div className='mob-pri-line1'></div>
                    <div className="mob-arrow" data-aos="fade-down"><HiArrowLongDown color='white' size="24px" /></div>
                    <div className="mob-elipse" ><img alt="circulo"src={circleb}></img></div>
                </div>

                <div className='mob-imgcont'><img alt="containers"className='config_imgcont' src={Cont2}></img></div>

                <div className='mob-bloco_azul'>
                    <div className="mob-titlebl">Visão e Valores
                    <div className="mob-principal-w-reta" data-aos="fade-right"></div>
</div>

                    <div className="mob-principal-text">Nossa visão é inovar com soluções inteligentes em equipamentos, mantendo a marca como referência e colaborar na conservação do meio ambiente.</div>
                    <div className='mob-pri-line2'></div>
                    <div className='mob-pri-line1'></div>
                    <div className="mob-arrow" data-aos="fade-down"><HiArrowLongDown color='white' size="24px" /></div>
                    <div className="mob-elipse" ><img alt="circulo"src={circle}></img></div>

                </div>
                
                <div className='mob-imgcont'><img alt="containers"className='config_imgcont' src={Cont3}></img></div>
                </div>
                <div className='bk'>
                    <div className="mob-reta-preta" data-aos="fade-down"></div>

                    <div className="mob-txt-titulo-loc">Localização</div>

                    <div className="mob-txtunidade">Unidade Manaus</div>
                    <div className="maps " data-aos="fade-right">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.977449395301!2d-59.91151498961163!3d-3.1006508968619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x926c059e93d6b04d%3A0xb2d1fc33f794c626!2sAlicon%20Terminal!5e0!3m2!1spt-BR!2sbr!4v1694099856433!5m2!1spt-BR!2sbr"
                            width="393" height="127" loading="lazy" className='mob-mapsgoogle'
                            title='mob-loc-manaus'
                            ></iframe>
                    </div>
                    <div className="mob-txt-endereco">Av.Cosme Ferreira</div>

                     

                        <div className="mob-mapinhas"  >
                            <div className="mob-txtunidade" >Unidade Boa Vista</div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63763.835020336664!2d-60.756580276970794!3d2.7451776884092114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8d93039c1b1811d3%3A0xf3b6ed357a6edf3a!2sTERMINAL%20ALICON%20BOA%20VISTA!5e0!3m2!1spt-BR!2sbr!4v1713344744111!5m2!1spt-BR!2sbr"
                                width="312"
                                height="108"
                                loading="lazy"
                                title='mob-loc-baovista'

                                className='mapsgoogle' data-aos="fade-right"></iframe>
                            <div className="mob-txt-endereco">Distrito Industrial Gov. Aquilino Mota Duarde.  </div>

                            <div className="mob-txtunidade">Unidade Itajaí</div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.2973184016373!2d-48.71396838912617!3d-26.894057676560944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8ccd7d5b83b7d%3A0xd74627c8f51eb39e!2sR.%20Francisco%20Reis%2C%20650%20-%20Cordeiros%2C%20Itaja%C3%AD%20-%20SC%2C%2088311-735!5e0!3m2!1spt-BR!2sbr!4v1713345431136!5m2!1spt-BR!2sbr"
                                width="312"
                                height="108"
                                loading="lazy"
                                className='mapsgoogle'
                                title='mob-loc-itajai'
                                data-aos="fade-right"
                            ></iframe>
                            <div className="mob-txt-endereco">Rua Francisco Reis, 650, Sala 03 Patio</div>

                        </div>
                        
                        <div className="mob-reta-preta2" data-aos="fade-down"></div>

                    <div className="mob-txt-titulo-loc">Nossos Principais Clientes</div>

                    <div className='mob-img_logos'>
                        <img alt="logo-samsung"src={samsung} className='mob-samsung'></img>
                        <img alt="logo-honda"src={honda} className='mob-honda'></img>
                        <img alt="logo-ministerio publico"src={defesa} className='mob-ministerio'></img>
                    </div>
                    <div className='mob-img_logos'>
                        <img alt="logo direcional"src={direcional} className='mob-direcional'></img>
                        <img alt="logo mineiraçao"src={mineiraco} className='mob-mineiracao'></img>
                        <img alt="logo taboca"src={taboca} className='mob-taboca'></img>
                    </div>
                    <div className="mob-reta-preta2" data-aos="fade-down"></div>

<div className="mob-txt-titulo-loc">Ouvidoria</div>
<div className='mob-txt-ouvidoria'>
    Deixe-nos saber sua opinião, sugestão ou ideias! <br />
    Envie uma mensagem sobre o que deseja falar:
</div>
<Ouvidoria />
                </div>
               
                <Footer></Footer>
            </MediaQuery>
        </>
    );
};
export default Inicial