import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
import Inicial from "../pages/principal/Inicial";
import Unidades  from "../pages/unidades/Unidades";
import Contatos from "../pages/contato/Contatos";
import Projetos from "../pages/projetos/Projetos";
import Servicos from "../pages/servicos/Servicos";
import Duvidas from "../pages/duvidas/Duvidas";
import LocMoveis from "../pages/pag_servicos/loc_moveis/LocMoveis";
import LocMaquinass from "../pages/pag_servicos/loc_maquinas/LocMaquinas";
import Fabricacao from "../pages/pag_servicos/fabricacao/Fabricacao";
import LocModulosH from "../pages/pag_servicos/loc_modulos_habitaveis/LocModulosH";
import LocContainers from "../pages/pag_servicos/loc_containers/LocContainers";
import VendaCont from "../pages/pag_servicos/venda_container/VendaCont";
import Armazenagem from "../pages/pag_servicos/armazanagem/Armazenagem";
import Transporte from "../pages/pag_servicos/trasnporte/Transporte";
import ScrollToTop from "../ScrollTop";
import ErrorPage from "../pages/Erro/ErrorPge";
import Manutencao from "../pages/pag_servicos/manuntencao/manuntencao";
import NavMobile from "../components/navbar/mobile/NavMob";
import NavMobileP from "../components/navbar/mobile/NavMobilePrin";

export default function Router(){
    return(
        <BrowserRouter>

            <ScrollToTop/>
          
            <Routes>
            <Route path="/" element={<Inicial></Inicial>}/> 

            <Route path="/servicos" element= {<Servicos></Servicos>}></Route>
            <Route path="/duvidas" element={<Duvidas></Duvidas>} ></Route>
            <Route path="/projetos" element={<Projetos></Projetos>}></Route>
            <Route path="/contatos"element={<Contatos></Contatos>}></Route>
            <Route path="/unidades"element={<Unidades></Unidades>}></Route>
            
            <Route path="/locacao de moveis"element={<LocMoveis/>}></Route>
            <Route path="/locacao de maquinas e equipamentos"element={<LocMaquinass/>}></Route>
            <Route path="/fabricacao"element={<Fabricacao/>}></Route>
            <Route path="/locacao de modulos habitaveis"element={<LocModulosH/>}></Route>
            <Route path="/locacao de containers"element={<LocContainers/>}></Route>
            <Route path="/venda de containers"element={<VendaCont/>}></Route>
            <Route path="/armazenagem" element={<Armazenagem/>}></Route>
            <Route path="/servicos de transporte" element={<Transporte/>}></Route>
            <Route path="/manutencao de container reefer"element={<Manutencao/>}></Route>
            <Route path="*" element= {<ErrorPage/>}/>


            </Routes>


         
        </BrowserRouter>
    );
}
