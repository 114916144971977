import './style.css'
const BlueSquareLine =(props)=>{
    return(
        <>
         <div className="rec-row">
            <div className="rec_container_blue">
            <div className="line-100"></div>
                <div className="title-rectangle">{props.title}</div>
                <div className="subtitle-rectangle">{props.subtitle}</div>
                
                <div className='rec-container-conteudo'>
                <div className="text-rectangle-line">
                    
                <div className='text-rectangle-line-bold'>{props.textbold}</div> <div>{props.text1}</div><div>{props.text2}</div>{props.text3}<div></div>{props.text4}</div>
                <div className='bsl-line-vert'></div>
                <div className="text-rectangle-line2">
                    
                <div className='text-rectangle-line-bold'>{props.textbold2}</div><div>{props.text5}</div><div>{props.text6}</div>{props.text7}<div></div>{props.text8}</div>
                </div>
                <div className="line-111"></div>

            </div>
            <div className="rec_bl_img">
                <img className="config_img" src={props.img}/>
            </div>
        </div>
        </>
    );
}
export default BlueSquareLine