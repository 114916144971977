import MediaQuery from "react-responsive";
import ProjetosDesktop from "./ProjetosDesktop";
import ProjetosMobile from "./ProjetosMobile";
const Projetos =()=>{
    return(
        <>
        <MediaQuery minWidth={1224}>
            <ProjetosDesktop/>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
            <ProjetosMobile/>
        </MediaQuery>
        </>
    );
}
export default Projetos